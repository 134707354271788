import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OrderTypeHeader from "../OrderTypeHeader";
import { useState } from "react";
import {
  getDataByID,
  getOrderItem,
  updateAssignedTo,
} from "../../../../../services/api";
import { svg } from "../../../../../services/svg";
import CustTable from "../../../../../components/Table/CustTable";
import { useSelector } from "react-redux";
import CustDropdown from "../../../../../components/CustInput/CustDropdown";

const Bundle = (props) => {
  const {
    user: { token },
    employee = [],
  } = useSelector((state) => state.appState);
  const store = useSelector((state) => state.appState);
  const { orderid, bundleid } = useParams();
  const [current_open_dropdown, setcurrent_open_dropdown] = useState("");
  const dropdown_settings = {
    is_open: current_open_dropdown,
    setdropdown: setcurrent_open_dropdown,
  };
  const navigate = useNavigate();
  const [nav, setnav] = useState("Overview");
  const [orderData, setorderData] = useState([
    {
      orderId: "1006",
      order: {
        _id: "664c64770265c63ca3a00196",
        orderId: "0014",
        lab: {
          name: "Thyrocare Technologies",
          orderId: "ASDKJA9123",
          technician: {
            name: "Sarvesh",
            phone: "9823045624",
          },
          report: "/report/report_id",
          result: {},
          activity: {
            type: "status change",
            by: {
              name: "System",
            },
            dateTime: "7:30 pm",
          },
        },
      },
      customer: {
        name: "John Doe",
        phone: "1234567890",
        address: {
          pincode: "560034",
          address: "5th Block",
          apt: "55",
          landmark: "Koramangala",
          city: "Banglore",
        },
      },
      product: {
        name: "Egg Freezing Consultation",
        type: "consult",
        sku: "DC02",
        goTo: "/user/user_id",
        bundle: {
          name: "Egg Freesing Bundle",
          goTo: "/bundle/bundle_id",
        },
      },
      status: "Order Confirmed",
      assignedTo: "System",
      priority: "Low",
      dates: {
        order: "21/05/2024",
        appointment: "Saturday, Apr 14, 11:00 AM",
      },
      amount: {
        gross: 5160,
        discount: {
          details: {
            name: "WELCOME20",
          },
        },
      },
      invoice: {
        invoiceId: "AH0014",
        goTo: "/invoice/invoice_id",
      },
    },
  ]);
  useEffect(() => {
    getDataByID(
      token,
      bundleid,
      "bundle",
      (orderItem) => {
        if (!orderData) {
          return;
        }
        //
        if (orderItem) {
          setorderData(
            orderItem.items.map((el) => {
              const {
                order,
                product_status,
                product_priority,
                dates,
                assigned_to,
                product_price,
                product_name,
                product_type,
                product_id,
                bundle_name,
                user,
                _id,
              } = el;

              return {
                orderId: order?.orderId || "",
                order: {
                  _id: order?._id || "",
                  orderId: order?.orderId || "",
                  lab: {
                    name: "",
                    orderId: "",
                    technician: {
                      name: "",
                      phone: "",
                    },
                    report: "/report/report_id",
                    result: {},
                    activity: {
                      type: "status change",
                      by: {
                        name: "System",
                      },
                      dateTime: "7:30 pm",
                    },
                  },
                },
                customer: {
                  name:
                    [
                      user?.user?.first_name || "",
                      user?.user?.last_name || "",
                    ].join(" ") || "",
                  phone: "1234567890",
                  goTo: "../../../user/user_id",
                  address: {
                    ...user.address,
                  },
                },
                product: {
                  name: product_name,
                  type: product_type,
                  sku: product_id,
                  goTo:
                    product_type == "guide"
                      ? ""
                      : "/" + product_type + "/" + _id,
                  bundle: {
                    name: orderItem?.bundleData?.name || "",
                    goTo: "",
                  },
                },
                status: product_status,
                assignedTo: assigned_to,
                priority: product_priority,
                dates: {
                  ...dates,
                },
                amount: {
                  gross: product_price,
                  discount: {
                    details: {
                      name: "",
                    },
                  },
                },
                invoice: {
                  invoiceId: order?.orderId ? "AH" + order.orderId : "",
                  goTo:
                    "/invoice/" + order?.orderId ? "AH" + order.orderId : "",
                },
              };
            })
          );
        }
      },
      () => {}
    );
  }, []);

  return (
    <div className="bundel_page dashboard_page">
      <OrderTypeHeader
        {...{
          breadCrumbs: [
            {
              name: orderData[0]?.order?.orderId
                ? "#" + orderData[0]?.order?.orderId
                : "NA",
            },
            {
              name: orderData[0]?.product?.bundle?.name || "NA",
              // goto: orderData[0]?.product.bundle?.goTo || "",
            },
          ],
          orderDate: orderData[0]?.dates?.order || "",
          customer: {
            name: orderData[0]?.customer?.name || "",
            goTo: orderData[0]?.customer?.goTo || "",
          },
          nav_list: ["Overview"],
          nav,
          setnav,
        }}
      />
      <div className="dashboard_body bundle_body">
        <div className="summary">
          <div className="amount_main_group">
            <div className="amount_group">
              <div className="title">Amount</div>
              <div className="body">
                {orderData[0]?.amount?.gross || <span className="na">N/A</span>}
              </div>
            </div>
            <div className="discount_group">
              <div className="title">Discount</div>
              <div className="body">
                {orderData[0]?.amount?.discount?.details?.name || (
                  <span className="na">N/A</span>
                )}
              </div>
            </div>
            {/* <div
              className="invoice_group"
              onClick={() => window.open(orderData[0]?.invoice?.goTo)}
            >
              <div className="title">Invoice</div>
              <div className="body">
                {orderData[0]?.invoice?.invoiceId
                  ? orderData[0]?.invoice?.invoiceId
                  : "-"}
                {svg.chain}
              </div>
            </div> */}
          </div>
        </div>
        <div className="bundle_items">
          <CustTable
            {...{
              data: orderData.map(
                ({ dates, order, product, status, assignedTo }) => ({
                  productName: product?.name || "",
                  orderStatus: (
                    <div className="order_status">{status || ""}</div>
                  ),
                  assignedTo: assignedTo ? (
                    <CustDropdown
                      {...{
                        ...dropdown_settings,
                        dropdown_align: "",
                        multiselect: false,
                        // all: true,
                        hide_title: true,
                        title: product?.name,
                        value: assignedTo,
                        setvalue: (value) => {
                          if (value[0]) {
                            updateAssignedTo(
                              token,
                              value[0],
                              product?.goTo || "",
                              (success) => {
                                if (success?.success) {
                                  window.location.reload();
                                }
                              },
                              () => {}
                            );
                          }
                        },
                        list: [
                          ...employee?.map((el) => ({
                            key: el,
                            value: el,
                          })),
                        ],
                        list_show: (data) => data.value,
                      }}
                    />
                  ) : (
                    "NA"
                  ),
                  //  + product?.goTo
                  // orderDate: dates?.order ? dates.order : "",
                  // userName: customer?.name || "",
                  // orderId: order?.orderId ? "#" + order?.orderId : "",
                  rowClick: () => {
                    if (product?.goTo) {
                      navigate("." + product.goTo);
                    }
                  },
                })
              ),
              nosrnogap: true,
              header: [
                {
                  id: "productName",
                  title: "Product",
                  // width: 30,
                },
                {
                  id: "orderStatus",
                  title: "Status",
                  // width: 15,
                },
                ...(!["counselor", "doctor"].includes(
                  store?.user?.type?.toLowerCase()
                )
                  ? [
                      {
                        id: "assignedTo",
                        title: "Assigned To",
                        // width: 15,
                      },
                    ]
                  : []),
              ].flat(),
              onrowclick: (row_data) => {},
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Bundle;
