import React, { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { dateFormatter } from "../../utils/utils";
import { GetSlots, newBooking, updateRemider } from "../../services/api";

export const BookAppointment = ({
  orderData,
  type,
  setBookAppModal,
  testid,
  token,
  intake,
  labBookings,
}) => {
  const [AllSet, setAllSet] = useState({
    mindate: new Date(),
    maxdate: new Date(),
    date: new Date(),
    maintaindate: new Date(),
    time: "",
    currentslot: 0,
  });
  const [OverAllDateTime, setOverAllDateTime] = useState({});
  const [SlotsList, setSlotsList] = useState([]);

  useEffect(() => {
    let presentDate = new Date();
    let nextDate = new Date();
    // if (presentDate.getHours() >= 12) {
    nextDate = new Date(presentDate.getTime() + 24 * 60 * 60 * 1000);
    setAllSet((prev) => ({
      ...prev,
      mindate: nextDate,
      maintaindate: nextDate,
      date: nextDate,
    }));
    // }
    const sevenDaysMax = new Date(
      presentDate.getTime() + 7 * 24 * 60 * 60 * 1000
    );
    setAllSet((prev) => ({
      ...prev,
      maxdate: sevenDaysMax,
    }));
    GetSlots(
      token,
      {
        date: dateFormatter(nextDate, "YYYY-MM-DD"),
        pincode: orderData?.customer?.address?.pin_code,
        type: type,
        latitude: labBookings?.redcliffe?.latitude || 0,
        longitude: labBookings?.redcliffe?.longitude || 0,
      },
      (success) => {
        if (success) {
          setSlotsList(success);
        }
      }
    );
  }, [orderData]);

  return (
    <div className="booking_modal_main">
      <div className="booking_modal_inner">
        <div className="booking_title">Book appointment</div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className={"cust_input"}
            minDate={dayjs(dateFormatter(AllSet.mindate, "YYYY-MM-DD"))} //"year_long-month-date"
            sx={{
              width: "100%",
              "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--red) !important",
                outline: "0px !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--brand-peach-soda) !important",
                borderWidth: "2px !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "5px !important",
              },
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                borderRadius: "5px !important",
              },
            }}
            value={
              AllSet.maintaindate
                ? dayjs(dateFormatter(AllSet.maintaindate, "YYYY-MM-DD"))
                : AllSet.maintaindate
            }
            onChange={(value) => {
              setAllSet((prev) => ({
                ...prev,
                date: value,
                maintaindate: value,
              }));
              if (value?.$d) {
                setAllSet((prev) => ({
                  ...prev,
                  currentslot: 0,
                  time: "",
                }));
                setOverAllDateTime((prev) => ({
                  index: 0,
                }));
                GetSlots(
                  token,
                  {
                    date: dateFormatter(new Date(value), "YYYY-MM-DD"),
                    pincode: orderData?.customer?.address?.pin_code,
                    type: type,
                    latitude: labBookings?.redcliffe?.latitude || 0,
                    longitude: labBookings?.redcliffe?.longitude || 0,
                  },
                  (success) => {
                    if (success) {
                      setSlotsList(success);
                    }
                  },
                  (err) => {}
                );
              }
            }}
            format="DD/MM/YYYY"
          />
        </LocalizationProvider>

        {dateFormatter(new Date(AllSet.maintaindate), "YYYY-MM-DD") <
          new Date(AllSet.mindate).toISOString().split("T")[0] ||
        dateFormatter(new Date(AllSet.maintaindate), "YYYY-MM-DD") >
          new Date(AllSet.maxdate).toISOString().split("T")[0] ? (
          <div className="no_time_slot_admin">
            You can only book slots 7 days into the future.
          </div>
        ) : (
          <div className="your_time_slot_admin">
            <div className="your_date_slot">
              {SlotsList?.map((el, i) => (
                <div
                  key={i}
                  className={
                    "your_date_slot_box " +
                    (new Date(AllSet.date).getDate().toString() ===
                    new Date(el.dateTime).getDate().toString()
                      ? " your_date_slot_box_active"
                      : "")
                  }
                  onClick={() => {
                    setAllSet((prev) => ({
                      ...prev,
                      date: el.dateTime,
                      currentslot: i,
                      time: "",
                    }));
                    setOverAllDateTime((prev) => ({
                      index: i,
                    }));
                  }}
                >
                  {dateFormatter(new Date(el.dateTime), "DD MMS")}
                </div>
              ))}
            </div>
            <div className="your_time_slot_data_set">
              {SlotsList[AllSet.currentslot]?.slots?.map((el, i) => {
                let cs = el.slot.split("-").map((el1) => el1.trim(""));
              }).length === 0 ? (
                <div className="no_time_slot_admin">
                  {dateFormatter(new Date(AllSet.date), "YYYY-MM-DD") <
                    new Date(AllSet.mindate).toISOString().split("T")[0] ||
                  dateFormatter(new Date(AllSet.date), "YYYY-MM-DD") >
                    new Date(AllSet.maxdate).toISOString().split("T")[0]
                    ? "You can only book slots 7 days into the future."
                    : "No Slots Available"}
                </div>
              ) : (
                SlotsList[AllSet.currentslot]?.slots?.map((el, i) => {
                  let cs = el.slot.split("-").map((el1) => el1.trim(""));

                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setAllSet((prev) => ({
                          ...prev,
                          time: el,
                        }));
                        setOverAllDateTime((prev) => ({
                          ...prev,
                          date: AllSet.date,
                          time: el,
                        }));
                      }}
                      className={
                        "time_box " +
                        (el.slot === AllSet?.time?.slot ? " active_box" : "")
                      }
                    >
                      {el.slot}
                      <div className="time_box_tick">
                        {el.slot === AllSet?.time?.slot ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM13.707 8.707C13.8892 8.5184 13.99 8.2658 13.9877 8.0036C13.9854 7.7414 13.8802 7.49059 13.6948 7.30518C13.5094 7.11977 13.2586 7.0146 12.9964 7.01233C12.7342 7.01005 12.4816 7.11084 12.293 7.293L9 10.586L7.707 9.293C7.5184 9.11084 7.2658 9.01005 7.0036 9.01233C6.7414 9.0146 6.49059 9.11977 6.30518 9.30518C6.11977 9.49059 6.0146 9.7414 6.01233 10.0036C6.01005 10.2658 6.11084 10.5184 6.293 10.707L8.293 12.707C8.48053 12.8945 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8945 9.707 12.707L13.707 8.707Z"
                              fill="#5F1D1B"
                            />
                          </svg>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}
        <div className="between_line"></div>
        <div className="booking_controler_section">
          <div className="booking_controler_detail">
            <div className="booking_controler_detail_1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 6.66667V10L12.5 12.5M17.5 10C17.5 10.9849 17.306 11.9602 16.9291 12.8701C16.5522 13.7801 15.9997 14.6069 15.3033 15.3033C14.6069 15.9997 13.7801 16.5522 12.8701 16.9291C11.9602 17.306 10.9849 17.5 10 17.5C9.01509 17.5 8.03982 17.306 7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
                  stroke="#777777"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              TIMESLOT
            </div>
            {AllSet?.date?.$d != "Invalid Date" &&
            (dateFormatter(new Date(AllSet?.date), "YYYY-MM-DD") <
              new Date(AllSet.mindate).toISOString().split("T")[0] ||
              dateFormatter(new Date(AllSet?.date), "YYYY-MM-DD") >
                new Date(AllSet?.maxdate).toISOString().split("T")[0]) ? (
              <div className="booking_controler_detail_2">
                Set reminder for:
              </div>
            ) : (
              ""
            )}
            <div className="booking_controler_detail_3">
              {dateFormatter(new Date(AllSet?.date), "YYYY-MM-DD") <
                new Date(AllSet?.mindate).toISOString().split("T")[0] ||
              dateFormatter(new Date(AllSet?.date), "YYYY-MM-DD") >
                new Date(AllSet?.maxdate).toISOString().split("T")[0]
                ? AllSet?.date?.$d != "Invalid Date"
                  ? dateFormatter(new Date(AllSet?.date), "MMS DD, YYYY")
                  : ""
                : OverAllDateTime?.date
                ? `${dateFormatter(
                    new Date(OverAllDateTime?.date),
                    "MMS DD, YYYY"
                  )} at ${OverAllDateTime?.time?.slot.split("-")[0]} `
                : ""}
            </div>
          </div>
          <div className="booking_controler_btns_stn">
            <div
              className="booking_btn_canc"
              onClick={() => {
                setBookAppModal("");
              }}
            >
              Cancel
            </div>
            {dateFormatter(new Date(AllSet?.date), "YYYY-MM-DD") <
              new Date(AllSet?.mindate).toISOString().split("T")[0] ||
            dateFormatter(new Date(AllSet?.date), "YYYY-MM-DD") >
              new Date(AllSet?.maxdate).toISOString().split("T")[0] ? (
              <div
                className={
                  "booking_btn_save " +
                  (dateFormatter(new Date(AllSet?.date), "YYYY-MM-DD") >
                    new Date(AllSet?.maxdate).toISOString().split("T")[0] &&
                  AllSet?.date?.$d != "Invalid Date"
                    ? " book_active"
                    : "")
                }
                onClick={() => {
                  if (
                    dateFormatter(new Date(AllSet?.date), "YYYY-MM-DD") >
                      new Date(AllSet?.maxdate).toISOString().split("T")[0] &&
                    AllSet?.date.$d != "Invalid Date"
                  ) {
                    updateRemider(
                      token,
                      dateFormatter(new Date(AllSet?.date), "YYYY-MM-DD"),
                      testid,
                      (success) => {
                        setBookAppModal("");
                        window.location.reload();
                      }
                    );
                  }
                }}
              >
                Save
              </div>
            ) : (
              <div
                className={
                  "booking_btn_save  " +
                  (OverAllDateTime?.date ? " book_active" : "")
                }
                onClick={() => {
                  if (OverAllDateTime?.date) {
                    newBooking(
                      token,
                      {
                        type: type,
                        date: dateFormatter(
                          new Date(OverAllDateTime?.date),
                          "YYYY-MM-DD"
                        ), // "2024-07-25",
                        time: OverAllDateTime?.time?.slot?.split(" - ")[0],
                        slot_id: OverAllDateTime?.time?.slotMasterId,
                        address: [
                          orderData?.customer?.address?.apt || "",
                          orderData?.customer?.address?.address || "",
                          orderData?.customer?.address?.landmark || "",
                          orderData?.customer?.address?.city || "",
                          orderData?.customer?.address?.state || "",
                          orderData?.customer?.address?.pin_code || "",
                        ]
                          .filter((el) => el.trim())
                          .join(", "),
                        landmark: orderData.customer.address.landmark,
                        pincode: orderData.customer.address.pin_code,
                        birthday: dateFormatter(
                          new Date(intake.dob),
                          "YYYY-MM-DD"
                        ), // "1999-04-27",
                        latitude: labBookings?.redcliffe?.latitude || 0,
                        longitude: labBookings?.redcliffe?.longitude || 0,
                        name: orderData.customer.name,
                        mobile: orderData.customer.phone,
                        product: orderData.product.sku,
                        user_id: "",
                        order_item_id: testid,
                        comming_period: "",
                      },
                      (success) => {
                        setBookAppModal("");
                        window.location.reload();
                      }
                    );
                  }
                }}
              >
                Save & notify
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
