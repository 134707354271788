import React, { Children, useEffect, useLayoutEffect, useState } from "react";

import { Outlet, useNavigate, useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./Pages/Login/Login";
import Error from "./Pages/Error/Error";
import { bc } from "./services/BroadcastLocal";
import {
  checkToken,
  getEmployee,
  getLabData,
  getProducts,
} from "./services/api";

import Dashboard from "./Pages/Dashboard/Dashboard";
import Orders from "./Pages/Dashboard/Pages/Orders/Orders";
import Customers from "./Pages/Dashboard/Pages/Customers/Customers";
import OrderById from "./Pages/Dashboard/Pages/Orders/OrderById/OrderById";
import DashboardHeader from "./Pages/Dashboard/Pages/DashboardHeader";
import Consult from "./Pages/Dashboard/Pages/Orders/Consult/Consult";
import Guide from "./Pages/Dashboard/Pages/Orders/Guide/Guide";
import Test from "./Pages/Dashboard/Pages/Orders/Test/Test";
import Coaching from "./Pages/Dashboard/Pages/Orders/Coaching/Coaching";
import Testing from "./Pages/Testing/Testing";
import Bundle from "./Pages/Dashboard/Pages/Orders/Bundle/Bundle";
import CustomerView from "./Pages/Dashboard/Pages/Customers/CustomerView/CustomerView";
import Reports from "./Pages/Dashboard/Pages/Reports/Reports";
import Discounts from "./Pages/Dashboard/Pages/Discounts/Discounts";
import Products from "./Pages/Dashboard/Pages/Products/Products";

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((state) => state.appState);
  const { user, flags } = useSelector((state) => state.appState);

  const nav = [
    {
      title: "Dashboard",
      path: "dashboard",
      element: (
        <Dashboard>
          <Outlet />
        </Dashboard>
      ),
      svg: "",
      children: [
        {
          path: "orders/all",
          element: <Orders page="0" />,
        },
        {
          path: "orders/active",
          element: <Orders page="1" />,
        },
        {
          path: "orders/assigned-to-me",
          element: <Orders page="2" />,
        },
        {
          path: "orders/action-required",
          element: <Orders page="3" />,
        },

        {
          path: "orders/:orderid",
          element: <Outlet />,
          children: [
            {
              path: "test/:testid/:page?/:subnav?",
              element: <Test />,
            },
            {
              path: "consult/:consultid",
              element: <Consult />,
            },
            {
              path: "coaching/:coachingid",
              element: <Coaching />,
            },
            {
              path: "guide/:guideid",
              element: <Guide />,
            },
            {
              path: "bundle/:bundleid",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <Bundle />,
                },
                {
                  path: "test/:testid/:page?/:subnav?",
                  element: <Test {...{ is_bundle: true }} />,
                },
                {
                  path: "consult/:consultid",
                  element: <Consult {...{ is_bundle: true }} />,
                },
                {
                  path: "coaching/:coachingid",
                  element: <Coaching {...{ is_bundle: true }} />,
                },
                {
                  path: "guide/:guideid",
                  element: <Guide {...{ is_bundle: true }} />,
                },
              ],
            },
          ],
        },
        ...(!["counselor", "doctor"].includes(store?.user?.type?.toLowerCase())
          ? [
              {
                path: "customers",
                element: <Outlet />,
                children: [
                  {
                    path: "all",
                    element: <Customers />,
                  },
                  {
                    path: ":customerid/:page",
                    element: <CustomerView />,
                  },
                ],
              },
              {
                path: "report",
                element: <Reports />,
              },
              {
                path: "products",
                element: <Products />,
              },
              {
                path: "discounts",
                element: <Outlet />,
                children: [
                  {
                    path: "",
                    element: <Discounts />,
                  },
                  {
                    path: ":id",
                    element: <Discounts />,
                  },
                ],
              },
            ]
          : []),
      ].flat(),
    },
    {
      path: "/*",
      element: <Error />,
    },
  ];
  const routes = [
    {
      path: "",
      element: <Login />,
    },
    ...nav,
    {
      path: "/*",
      element: <Error />,
    },
  ];
  useEffect(() => {
    setTimeout(() => {
      getProducts(
        (response) => {
          let products = [];
          let products_by_id = {};
          let bundles = [];
          response.data.explore.map((el) => {
            const { sku, servicesIncluded, _id } = el;
            if (servicesIncluded) {
              // bundles[sku] = { ...el };
              bundles.push({ ...el });
            } else {
              products_by_id[el._id] = { ...el };
              products.push({ ...el });
            }
          });
          bundles = bundles.map((el) => ({
            ...el,
            servicesIncluded: el.servicesIncluded.map((el1) => ({
              ...products_by_id[el1._ref],
            })),
          }));
          dispatch({
            type: "setAppState",
            subType: "products",
            payload: {
              products,
              bundles,
              api: {
                is_products: true,
                is_bundles: true,
              },
            },
          });
        },
        (error) => {
          //
        }
      );
    }, 0);
  }, []);
  useEffect(() => {
    if (user?.token && flags?.token_validated) {
      // navigate("/dashboard/orders/all");
    }
    if (!flags?.token_validated && !user?.token) {
      navigate("/");
    } else if (user?.token && !flags?.token_validated) {
      checkToken(
        user.token,
        ({ name, type }) => {
          if (name) {
            dispatch({
              type: "setAppState",
              subType: "user",
              payload: { name, type, token: user.token },
            });
            getEmployee(
              user.token,
              (success) => {
                dispatch({
                  type: "setAppState",
                  subType: "employee",
                  payload: [
                    ...new Set(
                      success.map((el) => el?.name || "").filter((el) => el)
                    ),
                  ],
                });
              },
              () => {}
            );
            const getData = () =>
              getLabData(
                user.token,
                ({ biomarkerList, biomarkers, reports, reportsList }) => {
                  // () => {

                  let product_list = Object.keys(reportsList);
                  let report = {};
                  product_list.map((el) => {
                    report[el] = {};
                    report[el].cards = reportsList[el].map((id) => reports[id]);
                    report[el].biomarkers = biomarkerList[el].map(
                      (code) => biomarkers[code]
                    );
                    return null;
                  });
                  dispatch({
                    type: "setAppState",
                    subType: "report",
                    payload: report,
                  });
                },
                (reject) => {
                  // getData();
                }
              );
            getData();
            if (!window.location.pathname) {
              navigate("/dashboard/orders/all");
            }
          } else {
            navigate("");
          }
        },
        () => {
          navigate("");
        }
      );
    }
  }, [user]);

  return (
    <div className="layout">
      {/* <Navigation {...{ nav }} /> */}
      {useRoutes(routes)}
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        // transition="Bounce"
      />
    </div>
  );
};

export default Layout;
