import React from "react";
import { useNavigate } from "react-router-dom";
import { svg } from "../../../../services/svg";

const OrderTypeHeader = ({
  breadCrumbs = [],
  nav_list,
  nav,
  setnav,
  customer,
  orderDate,
}) => {
  const navigate = useNavigate();
  //
  return (
    <div className="order_type_header">
      <div className="header header1">
        <div className="breadcrumb">
          {breadCrumbs?.map(({ name, goto }, i) => (
            <>
              <div
                className="breadcrumb_items"
                onClick={() => (goto ? navigate(goto) : "")}
              >
                {name}
              </div>
              {breadCrumbs.length == i + 1 || i == 0 ? "" : svg.chevron.right}
            </>
          ))}
        </div>
        <div className="txt1">
          {customer?.phone || ""}
          <div
            onClick={() => {
              navigator.clipboard.writeText(customer?.phone);
            }}
          >
            {svg.clipboard}
          </div>
        </div>
      </div>
      <div className="header header2">
        {orderDate ? <div className="date">{orderDate}</div> : ""}
        <div className="divider"></div>

        <div
          className="username"
          onClick={() =>
            customer?.goTo
              ? navigate(
                  window.location.pathname
                    .split("/")
                    .map((el) => "../")
                    .join("") + customer?.goTo
                )
              : ""
          }
        >
          {customer?.name || ""}
        </div>
        <div className="txt1">
          {customer?.email || ""}
          <div
            onClick={() => {
              navigator.clipboard.writeText(customer?.email);
            }}
          >
            {svg.clipboard}
          </div>
        </div>
      </div>
      <div className="header header3">
        {nav_list?.map((el) => (
          <div
            className=""
            active={el.toLowerCase() === nav.toLowerCase() ? "true" : ""}
            onClick={() => setnav(el)}
          >
            {el}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderTypeHeader;
