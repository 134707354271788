import React, { useEffect, useState } from "react";
import ActivityTracker from "../Orders/components/ActivityTracker/ActivityTracker";
import CustDropdown from "../../../../components/CustInput/CustDropdown";
import { useNavigate } from "react-router-dom";
import { publishReports, updateReports } from "../../../../services/api";
import { useSelector } from "react-redux";
import { LevelListForAdmin } from "../Orders/Test/LevelListForAdmin";
import {
  calculateAge,
  createCards,
  labRangeMaker,
} from "../../../../utils/utils";

const Results = ({
  // results,
  activity,
  // current_order,
  userData,
  // testid,
  reports,
  intake,
}) => {
  const {
    user: { token },
    labData,
  } = useSelector((state) => state.appState);
  const store = useSelector((state) => state.appState);

  const navigate = useNavigate();

  const [all_reports, setall_reports] = useState([]);
  const [curret_seletced, setcurret_seletced] = useState({});

  const [current_open_dropdown, setcurrent_open_dropdown] = useState("");

  const dropdown_settings = {
    is_open: current_open_dropdown,
    setdropdown: setcurrent_open_dropdown,
  };

  const [currnetReport, setcurrnetReport] = useState({});
  const [result_filter, setresult_filter] = useState([]);
  const [reportPDF, setreportPDF] = useState("");
  const [reportPDFBase64, setreportPDFBase64] = useState("");
  const [resultBiomarkers, setresultBiomarkers] = useState([]);
  const [current_open_level, setcurrent_open_level] = useState("");
  const [ResultCards, setResultCards] = useState({});

  const [result_values, setresult_values] = useState({
    // T4: {
    //   min: 0,
    //   max: 0,
    //   value: "11",
    // },
    // BMI: {
    //   value: "20",
    // },
  });
  const [edit_result, setedit_result] = useState(false);

  useEffect(() => {
    if (!(reports?.length > 0) || Object.keys(labData || {}).length == 0) {
      return;
    }
    let user_age = calculateAge(intake?.dob) || 0;
    let computed_reports = reports.map((el) => {
      let bmi = 0;
      let sku = el?.order_id?.product_id || "";
      if (!sku) {
        return {};
      }
      const weightInKg = parseFloat(intake?.personalize?.weight || 0);
      const heightInInches =
        parseFloat(intake?.personalize?.height_feet || 0) * 12 +
        parseFloat(intake?.personalize?.height_inch || 0);

      if (weightInKg > 0 && heightInInches > 0) {
        const heightInM = heightInInches * 0.0254;
        const bmiValue = weightInKg / (heightInM * heightInM);
        bmi = bmiValue.toFixed(2);
      }

      let biomarkers = {
        BMI: {
          value: bmi,
        },
      };
      el?.results?.map(
        ({ shortCode, value }) => (biomarkers[shortCode] = { value })
      );

      let formated_biomarkers = labRangeMaker({
        ...labData[sku],
        biomarker: [...labData[sku].biomarkers],
      }).map((el) => {
        let t = {
          ...el,
          ...el.labRange(
            user_age,
            {
              value: "",
              score: "pending",
              ...(biomarkers ? biomarkers[el?.biomarkerCode] || {} : {}),
            }
            // user_data?.labname "api"
          ),
        };
        delete t.labRange;
        return t;
      });
      return {
        date: el.order_id?.dates?.order,
        biomarkers: formated_biomarkers,
        test_id: el?.order_id?._id,
        cards: createCards(labData[sku].cards, formated_biomarkers, intake),
      };
    });
    setall_reports(computed_reports);
    setcurret_seletced(computed_reports.slice(-1)[0]);
  }, [reports, labData]);

  return (
    <div className="result_main">
      {all_reports.length > 0 ? (
        <>
          <div className="result_header">
            <div>
              <CustDropdown
                {...{
                  ...dropdown_settings,
                  dropdown_align: "left_align",
                  multiselect: false,
                  // all: true,
                  title: curret_seletced ? curret_seletced.date : "Reports",
                  value: [curret_seletced?.test_id || ""],
                  setvalue: (value) => {
                    if (value[0]) {
                      setcurret_seletced(
                        all_reports.filter((el) => el.test_id == value[0])[0]
                      );
                      // navigate(value[0]);
                    }
                  },
                  list: [
                    ...all_reports.map((el, i) => ({
                      key: el.test_id,
                      value: el.date,
                    })),
                  ],
                  list_show: (data) => data.value,
                }}
              />
            </div>

            {/* <div className="btn_group">
          <div
            className="chips"
            style={{
              // alignSelf: "stretch"
              height: 38.6,
            }}
            color="2"
          >
            Published
          </div>
        </div> */}
          </div>
          <div className="result_body">
            <div className="result_left" style={{ width: "50%" }}>
              <LevelListForAdmin
                {...{
                  to_show: curret_seletced.biomarkers,
                  value_of: {},
                  edit: false,
                  setresult_values,
                  setcurrent_open_level,
                  current_open_level,
                }}
              />
            </div>
            <div className="result_right cards_group" style={{ width: "50%" }}>
              {["fertility", "signs_of_pcos", "thyroid_health"].map((el) => {
                let data = "";
                try {
                  data = curret_seletced?.cards[el];
                } catch (err) {
                  data = "";
                }
                if (!data) {
                  return "";
                }
                return (
                  <div className="description">
                    <div className="desc_title">
                      {data?.summaryInsight?.heading || ""}
                    </div>
                    <div className="desc_body">
                      {data?.summaryInsight?.description || ""}
                    </div>
                    <div className="desc_chips">
                      {!data?.chips?.length
                        ? []
                        : [
                            ...data?.chips,
                            // {
                            //   txt: "Signs of Low Ovarian Reserve",
                            //   color: 0,
                            // },
                          ].map(({ txt, color }) => (
                            <div className="chips" color={color}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                              >
                                <path
                                  d="M9.00065 4.66602H14.334M14.334 4.66602V9.99935M14.334 4.66602L9.00065 9.99935L6.33398 7.33268L2.33398 11.3327"
                                  stroke="var(--svg_color,#F05E45)"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              {txt}
                            </div>
                          ))}
                    </div>
                  </div>
                );
              })}

              <label className="result_pdf">
                {!reportPDF ? (
                  "No report"
                ) : (
                  <div
                    className="pdf_show"
                    onClick={() => window.open(reportPDF)}
                  >
                    <div
                      className="pdf_img"
                      style={{
                        backgroundImage:
                          `url("https://res.cloudinary.com/doht5pjdp/image/fetch/f_auto,w_500,c_limit/` +
                          reportPDF +
                          `")`,
                      }}
                    ></div>
                    <div className="txt_group">
                      <div>
                        .../reports/pdf/
                        {decodeURIComponent(reportPDF.split("/").slice(-1)[0])}
                      </div>
                      <div className="chips" color="5">
                        Open Link
                      </div>
                    </div>
                  </div>
                )}
              </label>
            </div>
            {activity?.show ? (
              <div className="result_right">
                <ActivityTracker {...activity.data} />
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        "No Reports"
      )}
    </div>
  );
};

export default Results;
