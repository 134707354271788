import axios from "axios";
import { dateFormatter, discounts } from "../utils/utils";
import { logDOM } from "@testing-library/react";
import { isFocusable } from "@testing-library/user-event/dist/utils";

const base_url = "https://api.arva.health/api/v1/arva/";

export const getProducts = (if_successs, if_reject) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    // url: `${base_url}sanity/servicesv2`,
    url: `https://api.arva.health/sanity/services`,
    headers: {},
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const login = ({ email, password }, if_successs, if_reject) => {
  try {
    let data = JSON.stringify({
      email,
      password,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.arva.health/user/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if_successs({ ...response.data });
      })
      .catch((error) => {
        if_reject(error);
      });
  } catch {
    return if_reject({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const checkToken = (token, if_successs, if_reject) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.arva.health/user/token",
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if_successs({ ...response.data });
      })
      .catch((error) => {
        if_reject(error);
      });
  } catch {
    return if_reject({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const getOrders = (token, if_successs, if_reject) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.arva.health/orders",
      headers: {
        authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if_successs({ ...response.data });
      })
      .catch((error) => {
        if_reject(error);
      });
  } catch {
    return if_reject({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const getOrderItem = (token, { id, type }, if_successs, if_reject) => {
  return if_successs({
    status: true,
    orderItem: new Array(type == "bundle" ? 5 : 1).fill({
      // orderId: "1006",
      // lineItem: {
      order: {
        _id: "664c64770265c63ca3a00196",
        orderId: "0014",
        lab: {
          name: "Thyrocare Technologies",
          orderId: "ASDKJA9123",
          technician: {
            name: "Sarvesh",
            phone: "9823045624",
          },
          report: "/report/report_id",
          result: {},
          activity: {
            type: "status change",
            by: {
              name: "System",
            },
            dateTime: "7:30 pm",
          },
        },
      },
      customer: {
        name: "John Doe",
        phone: "1234567890",
        goTo: "/users/user_id",
        address: {
          pincode: "560034",
          address: "5th Block",
          apt: "55",
          landmark: "Koramangala",
          city: "Banglore",
        },
      },
      product: {
        name: "Egg Freezing Consultation",
        type: "consult",
        sku: "DC02",
        goTo: "/test/test_id",
        bundle: {
          name: "Egg Freesing Bundle",
          goTo: "/bundle/bundle_id",
        },
      },
      status: "Order Confirmed",
      assignedTo: "System",
      priority: "Low",
      dates: {
        order: "05/21/2024",
        appointment: "Saturday, Apr 14, 11:00 AM",
      },
      amount: {
        gross: 5160,
        discount: {
          details: {
            name: "WELCOME20",
          },
        },
      },
      invoice: {
        invoiceId: "AH0014",
        goTo: "/invoice/invoice_id",
      },
      // },
    }),
  });
  // try {
  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: "https://api.arva.health/orders",
  //     headers: {
  //       authorization: token,
  //     },
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       if_successs({ ...response.data });
  //     })
  //     .catch((error) => {
  //       if_reject(error);
  //     });
  // } catch {
  //   return if_reject({
  //     status: false,
  //     response: "Network Err or Try Again",
  //   });
  // }
};
export const getOrderItemCoaching = (
  token,
  { id, type },
  if_successs,
  if_reject
) => {
  return if_successs({
    status: true,
    orderItem: [...new Array(5)].map((el) => ({
      // orderId: "1006",
      // lineItem: {
      order: {
        _id: "664c64770265c63ca3a00196",
        orderId: "0014",
        lab: {
          name: "Thyrocare Technologies",
          orderId: "ASDKJA9123",
          technician: {
            name: "Sarvesh",
            phone: "9823045624",
          },
          report: "/report/report_id",
          result: {},
          activity: {
            type: "status change",
            by: {
              name: "System",
            },
            dateTime: "7:30 pm",
          },
        },
      },
      customer: {
        name: "John Doe",
        phone: "1234567890",
        goTo: "../../../customer/customer_id/overview",
        address: {
          pincode: "560034",
          address: "5th Block",
          apt: "55",
          landmark: "Koramangala",
          city: "Banglore",
        },
      },
      product: {
        name: "Fertility Health Coaching - 4 sessions",
        type: "consult",
        sku: "DC02",
        goTo: "/test/test_id",
        bundle: {
          name: "Egg Freesing Bundle",
          goTo: "/bundle/bundle_id",
        },
      },
      status: "Order Confirmed",
      assignedTo: "System",
      priority: "Low",
      dates: {
        order: "05/21/2024",
        appointment: "Saturday, Apr 14, 11:00 AM",
      },
      amount: {
        gross: 5160,
        discount: {
          details: {
            name: "WELCOME20",
          },
        },
      },
      invoice: {
        invoiceId: "AH0014",
        goTo: "/invoice/invoice_id",
      },
      // },
    })),
  });
  // try {
  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: "https://api.arva.health/orders",
  //     headers: {
  //       authorization: token,
  //     },
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       if_successs({ ...response.data });
  //     })
  //     .catch((error) => {
  //       if_reject(error);
  //     });
  // } catch {
  //   return if_reject({
  //     status: false,
  //     response: "Network Err or Try Again",
  //   });
  // }
};
export const getDataByID = (token, id, type, if_successs, if_reject) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/order/" + type + "/" + id,
    headers: {
      authorization: token,
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const addAppoiuntment = (
  token,
  id,
  eventData,
  if_successs,
  if_reject
) => {
  let data = JSON.stringify({
    ...eventData,
    // eventUrl: "https://www.google.com",
    // inviteeUrl: "https://www.google.com",
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/appointments/" + id + "/add-calendly-data",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const cancelAppoiuntment = (token, id, if_successs, if_reject) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/appointments/" + id + "/cancel",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const completeAppoiuntment = (token, id, if_successs, if_reject) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/appointments/" + id + "/complete",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const createAppoiuntment = (token, id = "", if_successs, if_reject) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/appointments/" + id + "/new-appointment",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const getDiscounts = (token, if_successs, if_reject) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/discounts",
    headers: {
      authorization: token,
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const createDiscounts = (token, data, if_successs, if_reject) => {
  let data1 = JSON.stringify({ ...data });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/discount/add",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data1,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const editDiscounts = (token, data, id, if_successs, if_reject) => {
  let data1 = JSON.stringify({ ...data });

  // return;
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/discount/" + id + "/edit",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data1,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const getCustomers = (token, if_successs, if_reject) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/customers",
    headers: {
      authorization: token,
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const getCustomersByID = (token, id, if_successs, if_reject) => {
  // return;
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/customer/" + id,
    headers: {
      authorization: token,
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const getBiomerByTest = (token, id, if_successs, if_reject) => {
  // return;
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/customer/" + id,
    headers: {
      authorization: token,
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const updateReports = (
  token,
  biomarker,
  id,
  is_new,
  if_successs,
  if_reject
) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: is_new
      ? "https://api.arva.health/test/" + id + "/new-report"
      : "https://api.arva.health/test/" + id + "/update-reports-available",
    headers: {
      Authorization: token,
    },
    data: {
      results: biomarker.results,
      // reportUrl: biomarker.reportUrl,
    },
  };
  if (biomarker?.reportUrl?.base64?.startsWith("data:")) {
    let data = JSON.stringify({
      base_64: biomarker.reportUrl.base64,
      name: biomarker.reportUrl.name.split(".").slice(0, -1).join("."),
    });

    let config1 = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.arva.health/test/" + id + "/upload-reports",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config1)
      .then((response) => {
        axios
          .request({
            ...config,
            data: {
              results: biomarker.results,
              report_url: response.data?.reportLink,
            },
          })
          .then((response1) => {
            if_successs(response1.data);
          })
          .catch((error1) => {
            if_reject(error1);
          });
      })
      .catch((error) => {
        if_reject(error);
      });
  } else {
    axios
      .request(config)
      .then((response) => {
        if_successs(response.data);
      })
      .catch((error) => {
        if_reject(error);
      });
  }
};
export const publishReports = (token, id, if_successs, if_reject) => {
  // let data = JSON.stringify(biomarker });
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/test/" + id + "/publish-reports",
    headers: {
      Authorization: token,
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};

export const getSanityClinics = (token, if_successs, if_reject) => {
  // return;

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/sanity/clinics",
    headers: {
      authorization: token,
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const UpdateClinics = (
  token,
  clinic_id,
  user_id,
  if_successs,
  if_reject
) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  let data = JSON.stringify({
    requestOn: `${year}-${month}-${day}`,
    clinicId: clinic_id,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/customer/" + user_id + "/update-clinic",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const AddOrderNotes = (
  token,
  notes,
  order_id,
  if_successs,
  if_reject
) => {
  let data = JSON.stringify({
    // "message": "Note Information",
    // "images": [
    //   "https://arva-lab-reports.s3.ap-south-1.amazonaws.com/Notes/652e336816c5614ff5c2726d/Images/jpeg/1111_d1.jpeg",
    //   "https://arva-lab-reports.s3.ap-south-1.amazonaws.com/Notes/652e336816c5614ff5c2726d/Images/jpeg/1111_d1.jpeg"
    // ]
    ...notes,
    images: notes.images || [],
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/activity/" + order_id + "/add-note",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const EditOrderNotes = (
  token,
  notes,
  note_id,
  if_successs,
  if_reject
) => {
  let data = JSON.stringify({
    ...notes,
    images: notes.images || [],
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/activity/" + note_id + "/edit-note",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const cancelfc1 = (token, id, if_successs, if_reject) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/test/" + id + "/add-fc1",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const completefc1 = (token, id, if_successs, if_reject) => {
  let data = "";

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/test/" + id + "/mark-as-complete",
    headers: {
      authorization:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im5pZGhpQGFydmEuaGVhbHRoIiwibmFtZSI6Ik5pZGhpIiwidHlwZSI6IkFkbWluIiwiaXNBZG1pbiI6dHJ1ZSwiaWF0IjoxNzE3NDg5MjYzfQ.xqEZp_55MZ5X4qoX-c0XxShzY6dbz1td0X0d8J8_8_4",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const addfc1 = (token, id, eventData, if_successs, if_reject) => {
  let data = JSON.stringify({
    eventUrl: "",
    inviteeUrl: "",
    ...eventData,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/test/" + id + "/add-fc1",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const createfiletolink = (
  order_id,
  fileData,
  if_successs,
  if_reject,
  loading
) => {
  let data = JSON.stringify({
    base_64: fileData.base64,
    name: fileData.name,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/activity/" + order_id + "/add-image",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      loading(percentCompleted);
    },
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};

export const GetSlots = (token, data1, ifsuccess, iferror) => {
  let data = {
    // type: "thyrocare",
    // date: "2024-06-15",
    // pincode: "400081",
    // latitude: 19.158,
    // longitude: 72.9598,
    ...data1,
  };
  if (!data1.pincode) {
    return;
  }
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/test/get-slots",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    // data: data,
  };
  let date = new Date(data1.date);
  let nextDate = new Date(date).setDate(date.getDate() + 1);
  let next1Date = new Date(date).setDate(date.getDate() + 2);
  Promise.all([
    axios.request({
      ...config,
      data: {
        ...data,
        date: dateFormatter(date, "YYYY-MM-DD"),
      },
    }),
    axios.request({
      ...config,
      data: {
        ...data,
        date: dateFormatter(nextDate, "YYYY-MM-DD"),
      },
    }),
    axios.request({
      ...config,
      data: {
        ...data,
        date: dateFormatter(next1Date, "YYYY-MM-DD"),
      },
    }),
  ])

    .then((response) => {
      ifsuccess(
        response.map((el, i) => ({
          ...[
            {
              dateTime: date.toString(),
              formated_date1: dateFormatter(date, "YYYY-MM-DD"),
            },
            {
              dateTime: new Date(nextDate).toString(),
              formated_date1: dateFormatter(nextDate, "YYYY-MM-DD"),
            },
            {
              dateTime: new Date(next1Date).toString(),
              formated_date1: dateFormatter(next1Date, "YYYY-MM-DD"),
            },
          ][i],

          slots: el.data.map(({ id, slot_start, slot_end }) => ({
            id: id,
            slot: [slot_start, slot_end].join(" - "),
            slotMasterId: id,
          })),
        }))
      );
    })
    .catch((error) => {
      iferror(error);
    });
};

export const updateRemider = (
  token,
  dateTime,
  order_id,
  ifsuccess,
  iferror
) => {
  let data = JSON.stringify({
    reminderDate: dateTime,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/order/test/" + order_id + "/update-reminder",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      ifsuccess(response.data);
    })
    .catch((error) => {
      iferror(error);
    });
};
export const newBooking = (token, booking_data, ifsuccess, iferror) => {
  let data = JSON.stringify({
    ...booking_data,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/test/new-booking",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      ifsuccess(response.data);
    })
    .catch((error) => {
      iferror(error);
    });
};

export const checkPincode = (token, pincode_address, ifsuccess, iferror) => {
  let data = JSON.stringify({
    ...pincode_address,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/test/check-pincode",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      ifsuccess(response.data);
    })
    .catch((error) => {
      iferror(error);
    });
};
export const updateIntake = (
  token,
  customer_id,
  intake,
  ifsuccess,
  iferror
) => {
  let data = JSON.stringify({
    ...intake,
  });
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/customer/" + customer_id + "/update-intake",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      ifsuccess(response.data);
    })
    .catch((error) => {
      iferror(error);
    });
};
export const AddCustomerNotes = (
  token,
  notes,
  customer_id,
  if_successs,
  if_reject
) => {
  let data = JSON.stringify({
    // "message": "Note Information",
    // "images": [
    //   "https://arva-lab-reports.s3.ap-south-1.amazonaws.com/Notes/652e336816c5614ff5c2726d/Images/jpeg/1111_d1.jpeg",
    //   "https://arva-lab-reports.s3.ap-south-1.amazonaws.com/Notes/652e336816c5614ff5c2726d/Images/jpeg/1111_d1.jpeg"
    // ]
    ...notes,
    images: notes.images || [],
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/customer/" + customer_id + "/add-note",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const EditCustomerNotes = (
  token,
  notes,
  note_id,
  if_successs,
  if_reject
) => {
  let data = JSON.stringify({
    ...notes,
    images: notes.images || [],
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/customer/" + note_id + "/update-note",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const getEmployee = (token, if_successs, if_reject) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/users",
    headers: {
      Authorization: token,
    },
    data: "",
  };

  axios.request(config).then((response) => {});
  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const updateAssignedTo = (
  token,
  assignedTo,
  order_id,
  if_successs,
  if_reject
) => {
  let data = JSON.stringify({
    assigned_to: assignedTo,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    // url: "https://api.arva.health/order/test/" + order_id + "/assign-to",
    url: "https://api.arva.health/order" + order_id + "/assign-to",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios.request(config).then((response) => {});
  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const getLabData = (token, if_successs, if_reject) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/sanity/reports",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
  };
  axios.request(config).then((response) => {});
  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
