import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OrderTypeHeader from "../OrderTypeHeader";
import { useState } from "react";
import {
  addfc1,
  cancelfc1,
  checkPincode,
  completefc1,
  getDataByID,
  publishReports,
  updateAssignedTo,
  updateIntake,
  updateReports,
} from "../../../../../services/api";
import { svg } from "../../../../../services/svg";
import CustTable from "../../../../../components/Table/CustTable";
import ActivityTracker from "../components/ActivityTracker/ActivityTracker";
import {
  calculateAge,
  createCards,
  dateFormatter,
  getCalendlyUrl,
  labRangeMaker,
} from "../../../../../utils/utils";
import { useSelector } from "react-redux";
import { LevelListForAdmin } from "./LevelListForAdmin";
import CustDropdown from "../../../../../components/CustInput/CustDropdown";
import AppointmentDropdown from "../components/AppointmentDropdown/AppointmentDropdown";
import { BookAppointment } from "../../../../../components/Modal/BookAppointment";
import IntakeView from "../../../../../components/Intake/IntakeView/IntakeView";
import Results from "../../components/Results";

const Test = ({ is_bundle }) => {
  const {
    user: { token },
    employee = [],
  } = useSelector((state) => state.appState);

  const store = useSelector((state) => state.appState);
  const [current_open_dropdown, setcurrent_open_dropdown] = useState("");

  const dropdown_settings = {
    is_open: current_open_dropdown,
    setdropdown: setcurrent_open_dropdown,
  };
  const [reportPDF, setreportPDF] = useState("");
  const [reportPDFBase64, setreportPDFBase64] = useState("");
  const [intakeold, setintakeold] = useState({});
  const [intake, setintake] = useState({});
  const [intake_edit, setintake_edit] = useState(false);
  const { orderid, bundleid, testid, page, subnav } = useParams();
  const navigate = useNavigate();
  const [current_open_level, setcurrent_open_level] = useState("");
  // const [nav, setnav] = useState("Overview");
  const [nav, setnav] = useState("Intake");
  useEffect(() => {
    if (page) {
      setnav(page);
    } else {
      if (!["counselor", "doctor"].includes(store?.user?.type?.toLowerCase())) {
        navigate("./overview/activity", { replace: true });
      } else {
        navigate("./results/activity", { replace: true });
      }
    }
  }, [page]);
  useEffect(() => {
    if (!["counselor", "doctor"].includes(store?.user?.type?.toLowerCase())) {
      // setnav("Overview");
    } else {
      setnav("Results");
      // setnav("FC1");
    }
  }, [store]);

  // const [nav, setnav] = useState("Results");
  const [resultBiomarkers, setresultsBiomarkers] = useState([]);
  const [user_data, setuser_data] = useState({
    age: 0,
    labname: "thyrocare",
  });
  const [currnetReport, setcurrnetReport] = useState({});
  const [ResultCards, setResultCards] = useState({});
  const [result_filter, setresult_filter] = useState([]);
  const [edit_result, setedit_result] = useState(false);
  const [result_values, setresult_values] = useState({
    // T4: {
    //   min: 0,
    //   max: 0,
    //   value: "11",
    // },
    // BMI: {
    //   value: "20",
    // },
  });

  const [labContent, setlabContent] = useState({
    biomarker: [],
    report: [],
  });
  const [show_report, setshow_report] = useState("");
  const [results_obj, setresults_obj] = useState({
    results: [],
    current_order: {},
    userData: {},
    testid,
  });

  const [orderData, setorderData] = useState([]);
  const [activity, setactivity] = useState([]);
  const [BookAppModal, setBookAppModal] = useState(false);
  const [labBookings, setlabBookings] = useState({
    redcliffe: {
      serviceable: false,
      latitude: 0,
      longitude: 0,
    },
    thyrocare: {
      serviceable: false,
      district: "",
      state: "",
    },
  });
  useEffect(() => {
    getDataByID(
      token,
      testid,
      "test",
      (orderItem) => {
        if (!orderData) {
          return;
        }
        const {
          item,
          // biomarkers,
          activity,
          userData,
          labContent,
          reports,
          amounts,
        } = orderItem;
        if (orderItem) {
          const {
            order,
            product_status,
            priority,
            dates,
            assigned_to,
            product_price,
            product_selling_price,
            product_name,
            product_type,
            product_id,
            user,
            lab_name,
            testId,
            technicianNumber,
            technicianName,
            _id,
            isPublished,
            fc1,
          } = item;
          setorderData([
            {
              orderId: order?.orderId || "",
              order: {
                _id: order?._id || "",
                orderId: order?.orderId || "",
                lab: {
                  name: lab_name,
                  orderId: testId,
                  technician: {
                    name: technicianName,
                    phone: technicianNumber,
                  },
                  report: "/report/report_id",
                  result: {},
                  activity: {
                    type: "status change",
                    by: {
                      name: "System",
                    },
                    dateTime: "7:30 pm",
                  },
                },
              },
              customer: {
                name: [
                  user?.user?.first_name || "",
                  user?.user?.last_name || "",
                ]
                  .join(" ")
                  .trim(),
                goTo: "dashboard/customers/" + user?.user_id + "/overview",
                // name: "test",
                phone: user.user.phone,
                email: user.user.email,
                address: {
                  ...user.address,
                },
              },
              product: {
                name: product_name,
                type: product_type,
                sku: product_id,
                // goTo: "/user/" + user._id,
                bundle: {
                  name: orderItem?.bundle?.name || "",
                  goTo: "/bundle/" + orderItem?.bundle?.bundleId || "",
                },
              },
              status: product_status,
              assignedTo: assigned_to,
              priority: priority,
              dates: {
                ...dates,
              },
              amount: {
                ...amounts,
              },
              invoice: {
                invoiceId: order?.orderId ? "AH" + order.orderId : "",
                goTo: "/invoice/" + order?.orderId ? "AH" + order.orderId : "",
              },
            },
          ]);
          setresults_obj({
            results: reports,
            current_order: item,
            userData: userData,
            testid,
          });

          let bmi = 0;
          const weightInKg = parseFloat(
            userData?.intake?.personalize?.weight || 0
          );
          // const heightInM =
          const heightInInches =
            parseFloat(userData?.intake?.personalize?.height_feet || 0) * 12 +
            parseFloat(userData?.intake?.personalize?.height_inch || 0);
          let t_result_values = {};
          if (weightInKg > 0 && heightInInches > 0) {
            const heightInM = heightInInches * 0.0254;
            const bmiValue = weightInKg / (heightInM * heightInM);
            bmi = bmiValue.toFixed(2);
            t_result_values = {
              BMI: {
                value: bmi,
              },
            };
          }
          let found_result_for_this_order = false;

          let t_result_filter = reports?.reduce((acc, curr) => {
            if (_id === curr?.order_id?._id) {
              found_result_for_this_order = true;
              let format_Result = curr?.results
                ? curr.results.reduce(
                    (acc1, curr1) => ({
                      ...acc1,
                      [curr1.shortCode]: {
                        value: curr1?.value || "",
                      },
                    }),
                    {}
                  )
                : {};
              setresult_values({
                ...format_Result,
                ...t_result_values,
              });
              return [
                ...acc,
                {
                  date: curr?.order_id?.dates?.order,
                  id: curr?.order_id?._id,
                  no_rediredt: true,
                  results: curr?.results || [],
                  report_id: curr?._id,
                  report_url: curr?.report_url || "",
                },
              ];
            }
            if (!found_result_for_this_order) {
              setresult_values({ ...t_result_values });
            }
            return [
              ...acc,
              {
                date: curr?.order_id?.dates?.order,
                id: curr?.order_id?._id,
                no_rediredt: false,
                results: curr?.results || [],
                report_id: curr?._id,
                report_url: curr?.report_url || "",
              },
            ];
          }, []);
          if (!found_result_for_this_order) {
            t_result_filter = [
              ...t_result_filter,
              {
                date: dates?.order || "",
                id: _id,
                no_rediredt: true,
                results: [],
              },
            ];
          }
          t_result_filter.sort((a, b) => {
            const dateA = new Date(a.date.split("/").reverse().join("-"));
            const dateB = new Date(b.date.split("/").reverse().join("-"));
            return dateA - dateB;
          });
          setresult_filter(t_result_filter);
          setintake({ ...(userData?.intake || {}) });
          setintakeold({ ...(userData?.intake || {}) });
          setuser_data({
            age: calculateAge(user.user.dob),
            labname: "thyrocare",
            id: userData?._id,
          });

          // let og_biomarker = labContent.biomarker.reduce(
          //   (acc, curr) => ({
          //     ...acc,
          //     [curr?.nameShort?.toLowerCase() || ""]: curr,
          //   }),
          //   {
          //     bmi: {
          //       name: "Body Mass Index",
          //       nameShort: "BMI",
          //       biomarkerCode: "BMI",
          //       description:
          //         "BMI is a measure of body fat based on height and weight that applies to adult men and women. It is used to categorize individuals into various weight statuses, which can indicate potential health risks.",
          //       insights: [
          //         {
          //           _id: "d1a5c0ef-1234-4c5f-987a-12345abc6789",
          //           score: "Underweight",
          //           description:
          //             "A BMI below 18.5 indicates that you are underweight, which may imply a risk of malnutrition or other health issues.",
          //         },
          //         {
          //           _id: "a2b3d4ef-2345-4d6e-890b-23456def7890",
          //           score: "Normal",
          //           description:
          //             "A BMI between 18.5 and 24.9 is considered normal and generally indicates a healthy weight.",
          //         },
          //         {
          //           _id: "c3d4e5f6-3456-4e7f-901c-34567fgh8901",
          //           score: "Overweight",
          //           description:
          //             "A BMI between 25 and 29.9 indicates that you are overweight, which may increase the risk of various health conditions.",
          //         },
          //         // {
          //         //     "_id": "d4e5f6g7-4567-4f8g-012d-45678ghi9012",
          //         //     "score": "Obese",
          //         //     "description": "A BMI of 30 or higher is classified as obese, which significantly raises the risk of health issues such as heart disease, diabetes, and high blood pressure."
          //         // }
          //       ],
          //       labRange: [
          //         {
          //           lab: "General Health Guidelines",
          //           lowMin: 0,
          //           lowMax: 18.5,
          //           optimalMin: 18.5,
          //           optimalMax: 24.9,
          //           highMin: 25,
          //           highMax: 29.9,
          //           unit: "kg/m²",
          //           // "obeseMin": 30,
          //           _id: "e5f6g7h8-5678-4g9h-123d-56789ijk0123",
          //           ageMin: 0,
          //           ageMax: 100,
          //         },
          //       ],
          //       _id: "f7g8h9i0-6789-4h0i-234d-67890jkl1234",
          //     },
          //   }
          // );

          setlabContent({
            ...labContent,
            biomarker: [...labContent.biomarker].sort((x, y) => {
              if (x.nameShort.toLowerCase() === "bmi") return -1;
              if (y.nameShort.toLowerCase() === "bmi") return 1;
              return 0;
            }),
          });
          // setresult_values({});
          setactivity(activity);
          const show_this_report = t_result_filter.filter(
            (el) => el.no_rediredt
          )[0];
          setcurrnetReport({
            ...show_this_report,
            is_published: isPublished,
            fc1,
          });
          setreportPDF(show_this_report.report_url);
        }
      },
      () => {}
    );
  }, [testid]);
  useEffect(() => {
    if (
      orderData[0]?.customer?.address?.pin_code &&
      orderData[0]?.customer?.address?.address &&
      orderData[0]?.customer?.address?.city
    ) {
      checkPincode(
        token,
        {
          pincode: orderData[0].customer.address.pin_code,
          address: `${orderData[0].customer.address.apt}, ${orderData[0].customer.address.address},${orderData[0].customer.address.landmark}, ${orderData[0].customer.address.city}, ${orderData[0].customer.address.state}`,
        },
        (success) => {
          setlabBookings(success);
        }
      );
    }
  }, [orderData]);

  useEffect(() => {
    if (labContent?.biomarker?.length > 0) {
      let t_ranges = labRangeMaker(labContent).map((el) => {
        let t = {
          ...el,
          ...el.labRange(
            user_data.age,
            {
              value: "",
              // min: 0,
              // max: 0,
              score: "pending",
              ...(result_values ? result_values[el?.biomarkerCode] || {} : {}),
            },
            user_data?.labname
          ),
        };
        delete t.labRange;
        return t;
      });
      setresultsBiomarkers([...t_ranges]);
      //
    }
  }, [labContent.biomarker, user_data.age, result_values]);

  useEffect(() => {
    if (labContent?.report?.length > 0) {
      setResultCards(createCards(labContent.report, resultBiomarkers, intake));
    }
  }, [labContent.report, resultBiomarkers, intake]);
  let activity_props = {
    data: activity,
    // order_id: orderData[0]?.order?._id,
    order_id: testid,
    token,
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setreportPDFBase64({ base64: reader.result, name: file.name });
      };
    } else {
      alert("Please upload a valid PDF file.");
    }
  };
  return (
    <div className=" dashboard_page">
      <OrderTypeHeader
        {...{
          breadCrumbs: [
            {
              name: orderData[0]?.order?.orderId
                ? "#" + orderData[0]?.order?.orderId
                : "NA",
            },
            is_bundle
              ? {
                  name: orderData[0]?.product?.bundle?.name || "NA",
                  goto: orderData[0]?.product?.bundle?.goTo
                    ? "../../../orders/" +
                      orderData[0]?.order?.orderId +
                      orderData[0]?.product?.bundle?.goTo
                    : "",
                }
              : null,
            {
              name: orderData[0]?.product?.name || "NA",
              goto: orderData[0]?.product?.goTo || "",
            },
          ].filter((el) => el),
          orderDate: orderData[0]?.dates?.order || "",
          customer: {
            name: orderData[0]?.customer?.name || "",
            goTo: orderData[0]?.customer?.goTo || "",
            phone: orderData[0]?.customer?.phone || "",
            email: orderData[0]?.customer?.email || "",
          },
          nav_list: [
            !["counselor", "doctor"].includes(store?.user?.type?.toLowerCase())
              ? ["Overview"]
              : [],
            "Results",
            "Intake",
            currnetReport?.is_published ? ["FC1"] : [],
          ].flat(1),
          // : ["Overview", "Results", "Intake"]
          nav,
          setnav: (somePage) => {
            navigate(
              `/dashboard/orders/${orderid}/test/${testid}/${somePage.toLowerCase()}/${
                somePage == "FC1" ? "notes" : subnav || "activity"
              }`,
              somePage
            );
          },
        }}
      />

      {orderData[0]?.orderId && (
        <div className="dashboard_body">
          {nav.toLowerCase() == "overview" &&
            !["counselor", "doctor"].includes(
              store?.user?.type?.toLowerCase()
            ) && (
              <>
                <div className="body_left">
                  <div className="summary">
                    <div className="amount_main_group">
                      <div className="status_group">
                        <div className="title">Status</div>
                        <div
                          //  className="body chips"
                          className="order_status chips"
                          {...{
                            color: {
                              High: 2,
                              Medium: 4,
                              Low: "",
                            }[orderData[0]?.priority || ""],
                          }}
                        >
                          {orderData[0]?.status || (
                            <span className="na">N/A</span>
                          )}
                        </div>
                      </div>
                      {
                        <div className="assignedto_group">
                          <div className="title">Assigned to</div>
                          <div className="body">
                            {orderData[0]?.assignedTo ? (
                              <CustDropdown
                                {...{
                                  ...dropdown_settings,
                                  dropdown_align: "left_align",
                                  multiselect: false,
                                  title: orderData[0]?.assignedTo,
                                  value: orderData[0]?.assignedTo,
                                  setvalue: (value) => {
                                    if (value[0]) {
                                      // navigate(value[0]);
                                      updateAssignedTo(
                                        token,
                                        value[0],
                                        "/test/" + testid,
                                        (success) => {
                                          if (success?.success) {
                                            window.location.reload();
                                          }
                                        },
                                        () => {}
                                      );
                                    }
                                  },
                                  list: [
                                    ...employee?.map((el) => ({
                                      key: el,
                                      value: el,
                                    })),
                                  ],
                                  list_show: (data) => data.value,
                                }}
                              />
                            ) : (
                              <span className="na">N/A</span>
                            )}
                          </div>
                        </div>
                      }
                    </div>
                    <div className="amount_main_group">
                      <div className="amount_group">
                        <div className="title">MRP</div>
                        <div className="body">
                          {orderData[0]?.amount?.net ||
                            -(<span className="na">N/A</span>)}
                        </div>
                      </div>
                      {orderData[0]?.amount?.net !=
                        orderData[0]?.amount?.amount && (
                        <>
                          <div className="amount_group">
                            <div className="title">Amount</div>
                            <div className="body">
                              {orderData[0]?.amount?.gross || (
                                <span className="na">N/A</span>
                              )}
                            </div>
                          </div>
                          <div className="discount_group">
                            <div className="title">Discount</div>
                            <div className="body">
                              {orderData[0]?.amount?.discount?.details
                                ?.name || <span className="na">N/A</span>}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="lab_summary">
                    <div className="title">Appointment</div>
                    <div className="address_group">
                      <div className="address">
                        {svg.location}
                        {[
                          orderData[0]?.customer?.address?.apt,
                          orderData[0]?.customer?.address?.address,
                          orderData[0]?.customer?.address?.landmark,
                          orderData[0]?.customer?.address?.city,
                          orderData[0]?.customer?.address?.pin_code,
                        ].join(", ")}
                        <div className="copy">{svg.clipboard}</div>
                      </div>
                      <div className="report_date">
                        {svg.clock}

                        {dateFormatter(
                          orderData[0]?.dates?.test
                            ?.split(" ")[0]
                            .split("/")
                            .reverse()
                            .join("-"),
                          "DD, MMF YYYY, " +
                            orderData[0]?.dates?.test?.split(" ")[1]
                        ) || (
                            <div>
                              <span>Reminder -</span>{" "}
                              {orderData[0].dates.period}
                            </div>
                          ) || <span className="tbd">TBD</span>}
                        {/* <div className="edit_btn">Edit</div> */}
                      </div>
                    </div>
                    <div className="lab_data_group">
                      <div className="lab_details">
                        <div className="title">LAB</div>
                        <div className="name">
                          {orderData[0]?.order?.lab?.name || (
                            <div className="tbd">TBD</div>
                          )}
                        </div>
                        <div className="lab_order_id">
                          {orderData[0]?.order?.lab?.orderId || (
                            <div className="tbd">TBD</div>
                          )}
                        </div>
                      </div>
                      <div className="technician_details">
                        <div className="title">TECHNICIAN DETAILS</div>
                        <div className="name">
                          {orderData[0]?.order?.lab?.technician?.name || (
                            <div className="tbd">TBD</div>
                          )}
                        </div>
                        <div className="phone">
                          {orderData[0]?.order?.lab?.technician?.phone || (
                            <div className="tbd">TBD</div>
                          )}
                          <div className="copy">{svg.clipboard}</div>
                        </div>
                      </div>
                    </div>
                    {console.log(labBookings)}
                    {orderData[0]?.order?.lab?.orderId ? (
                      ""
                    ) : (
                      <div className="lab_action_group">
                        <div className="">
                          Thyrocare -{" "}
                          {labBookings?.thyrocare?.serviceable ? (
                            <div
                              className="btn dark_btn"
                              onClick={() => {
                                setBookAppModal("thyrocare");
                              }}
                            >
                              {/* {orderData[0]?.dates?.test
                                ? "Reschedule"
                                : "Schedule"} */}
                              Schedule
                            </div>
                          ) : (
                            "Unserviceable"
                          )}
                        </div>
                        <div>
                          Redcliffe -{" "}
                          {labBookings?.redcliffe?.serviceable ? (
                            <div
                              className="btn dark_btn"
                              onClick={() => {
                                // alert("cannot book from admin");
                                setBookAppModal("redcliffe");
                              }}
                            >
                              {/* {orderData[0]?.dates?.test
                                ? "Reschedule"
                                : "Schedule"} */}
                              {/* Can be Scheduled */}
                              Schedule
                            </div>
                          ) : (
                            "Unserviceable"
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="body_right">
                  <ActivityTracker {...activity_props} />
                </div>
                {BookAppModal ? (
                  <BookAppointment
                    {...{
                      orderData: orderData[0],
                      type: BookAppModal,
                      setBookAppModal,
                      testid,
                      token,
                      intake,
                      labBookings,
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            )}
          {/* {nav.toLowerCase() == "results" && (
            <>
              <Results
                {...{
                  results: result_filter,
                  activity_props,
                  current_order: {},
                  userData: {},
                  testid,
                }}
              />
            </>
          )} */}
          {nav.toLowerCase() == "results" && (
            <>
              <div className="result_main">
                {!["counselor", "doctor"].includes(
                  store?.user?.type?.toLowerCase()
                ) ? (
                  <div className="result_header">
                    <div>
                      <CustDropdown
                        {...{
                          ...dropdown_settings,
                          dropdown_align: "left_align",
                          multiselect: false,
                          // all: true,
                          title: currnetReport ? currnetReport.date : "Reports",
                          value: "",
                          setvalue: (value) => {
                            if (value[0]) {
                              navigate(value[0]);
                            }
                          },
                          list: [
                            ...result_filter.map((el) => ({
                              key: el.no_rediredt
                                ? ""
                                : "../../orders/order_id/test/" + el.id,

                              value: el.date,
                            })),
                          ],
                          list_show: (data) => data.value,
                        }}
                      />
                    </div>
                    <div className="btn_group">
                      {!edit_result ? (
                        <div
                          className="btn"
                          onClick={() => {
                            setedit_result(true);
                          }}
                        >
                          Edit
                        </div>
                      ) : (
                        <div
                          className="btn"
                          onClick={() => {
                            let formated_data = Object.entries(
                              result_values
                            ).map((el) => ({
                              shortCode: el[0],
                              value: el[1].value,
                            }));
                            let t_result_filter = result_filter.filter(
                              (el) => el.id === testid
                            )[0];
                            let is_new = t_result_filter.results.length == 0;
                            let new_report_data = {
                              results: formated_data,
                            };
                            new_report_data.reportUrl =
                              reportPDFBase64 || reportPDF;
                            updateReports(
                              token,
                              { ...new_report_data },
                              is_new ? testid : t_result_filter.report_id,
                              is_new,
                              (success) => {
                                window.location.reload();
                              },
                              (error) => {}
                            );
                          }}
                        >
                          Save
                        </div>
                      )}
                      {currnetReport.is_published ? (
                        <div
                          className="chips"
                          style={{
                            height: 38.6,
                          }}
                          color="2"
                        >
                          Published
                        </div>
                      ) : (
                        <div
                          className="btn"
                          onClick={() => {
                            let formated_data = Object.entries(
                              result_values
                            ).map((el) => ({
                              shortCode: el[0],
                              value: el[1].value,
                            }));
                            let t_result_filter = result_filter.filter(
                              (el) => el.id === testid
                            )[0];
                            let is_new = t_result_filter.results.length == 0;
                            let new_report_data = {
                              results: formated_data,
                            };
                            new_report_data.reportUrl =
                              reportPDFBase64 || reportPDF;
                            updateReports(
                              token,
                              { ...new_report_data },
                              is_new ? testid : t_result_filter.report_id,
                              is_new,
                              (success) => {
                                publishReports(
                                  token,
                                  testid,
                                  (success) => {
                                    window.location.reload();
                                  },
                                  (error) => {}
                                );
                                // window.location.reload();
                              },
                              (error) => {}
                            );
                          }}
                        >
                          Publish
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="result_body">
                  <div className="result_left">
                    {/* {[
                      // {
                      //   name: "BMI",
                      //   short_name: "BMI",
                      //   desc: "",
                      //   value: 16.5,
                      //   value_txt: "Underweight",
                      //   color: 0,
                      //   unit: "kg/m2",
                      //   min: 20,
                      //   max: 25,
                      // },
                      // {
                      //   name: "Anti-mullerian hormone",
                      //   short_name: "AMH",
                      //   desc: "",
                      //   value: 1.2,
                      //   value_txt: "Low",
                      //   color: 0,
                      //   unit: "ng/ml",
                      //   min: 20,
                      //   max: 25,
                      // },
                      ...biomarkers,
                    ].map(
                      ({
                        name,
                        short_name,
                        desc,
                        value,
                        value_txt,
                        color,
                        unit,
                        min,
                        max,
                      }) => (
                        <div className="biomarker_main">
                          <div className="flex">
                            <div className="short_name">{short_name}</div>
                            <div className="value_txt" color={color}>
                              {value_txt}
                            </div>
                          </div>
                          <div className="flex">
                            <div className="name">{name}</div>
                            <div className="value" color={color}>
                              {value} {unit}
                            </div>
                          </div>
                        </div>
                      )
                    )} */}
                    <LevelListForAdmin
                      {...{
                        to_show: resultBiomarkers,
                        value_of: {},
                        edit: edit_result,
                        setresult_values,
                        setcurrent_open_level,
                        current_open_level,
                      }}
                    />
                  </div>
                  <div className="result_right cards_group">
                    {["fertility", "signs_of_pcos", "thyroid_health"].map(
                      (el) => {
                        let data = ResultCards[el];
                        if (!data) {
                          return "";
                        }
                        return (
                          <div className="description">
                            <div className="desc_title">
                              {data?.summaryInsight?.heading || ""}
                            </div>
                            <div className="desc_body">
                              {data?.summaryInsight?.description || ""}
                            </div>
                            <div className="desc_chips">
                              {!data?.chips?.length
                                ? []
                                : [
                                    ...data?.chips,
                                    // {
                                    //   txt: "Signs of Low Ovarian Reserve",
                                    //   color: 0,
                                    // },
                                  ].map(({ txt, color }) => (
                                    <div className="chips" color={color}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="16"
                                        viewBox="0 0 17 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M9.00065 4.66602H14.334M14.334 4.66602V9.99935M14.334 4.66602L9.00065 9.99935L6.33398 7.33268L2.33398 11.3327"
                                          stroke="var(--svg_color,#F05E45)"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                      {txt}
                                    </div>
                                  ))}
                            </div>
                          </div>
                        );
                      }
                    )}
                    {edit_result ? (
                      <label className="result_pdf" htmlFor="result_pdf">
                        <input
                          type="file"
                          id="result_pdf"
                          accept="application/pdf"
                          onChange={handleFileChange}
                        />
                        {reportPDFBase64?.name || "Add report"}
                      </label>
                    ) : (
                      <label className="result_pdf">
                        {!reportPDF ? (
                          "No report"
                        ) : (
                          <div
                            className="pdf_show"
                            onClick={() => window.open(reportPDF)}
                          >
                            <div
                              className="pdf_img"
                              style={{
                                backgroundImage:
                                  `url("https://res.cloudinary.com/doht5pjdp/image/fetch/f_auto,w_500,c_limit/` +
                                  reportPDF +
                                  `")`,
                              }}
                            ></div>
                            <div className="txt_group">
                              {/* <h3>Click here to open report</h3> */}
                              <div>
                                .../reports/pdf/
                                {decodeURIComponent(
                                  reportPDF.split("/").slice(-1)[0]
                                )}
                              </div>
                              <div className="chips" color="5">
                                Open Link
                              </div>
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="icon-import"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <link
                                  xmlns=""
                                  type="text/css"
                                  rel="stylesheet"
                                  id="dark-mode-custom-link"
                                />
                                <link
                                  xmlns=""
                                  type="text/css"
                                  rel="stylesheet"
                                  id="dark-mode-general-link"
                                />
                                <style
                                  xmlns=""
                                  lang="en"
                                  type="text/css"
                                  id="dark-mode-custom-style"
                                />
                                <style
                                  xmlns=""
                                  lang="en"
                                  type="text/css"
                                  id="dark-mode-native-style"
                                />
                                <style
                                  xmlns=""
                                  lang="en"
                                  type="text/css"
                                  id="dark-mode-native-sheet"
                                />
                                <path
                                  d="M17 12.5L18.6716 10.8284C20.2337 9.26634 20.2337 6.73368 18.6716 5.17158L18.3284 4.82843C16.7663 3.26634 14.2337 3.26634 12.6716 4.82843L10.8284 6.67158C9.26633 8.23368 9.26634 10.7663 10.8284 12.3284L11 12.5M7.00001 10.5L4.82843 12.6716C3.26634 14.2337 3.26634 16.7663 4.82843 18.3284L5.17158 18.6716C6.73368 20.2337 9.26634 20.2337 10.8284 18.6716L13.1716 16.3284C14.7337 14.7663 14.7337 12.2337 13.1716 10.6716L13 10.5"
                                  stroke="var(--stroke-color, black)"
                                  stroke-width="var(--stroke-width, 1.5)"
                                  vector-effect="non-scaling-stroke"
                                />
                              </svg> */}
                            </div>
                          </div>
                        )}
                      </label>
                    )}
                  </div>

                  <div className="result_right">
                    <ActivityTracker {...activity_props} />
                  </div>
                </div>
              </div>
            </>
          )}
          {nav.toLowerCase() == "intake" && (
            <div className="invoice_main">
              {!["counselor", "doctor"].includes(
                store?.user?.type?.toLowerCase()
              ) ? (
                <div className="actions">
                  {intake_edit ? (
                    <>
                      <div className="btn " onClick={() => setintake_edit("")}>
                        Cancel
                      </div>
                      <div
                        className="btn dark_btn"
                        style={{
                          opacity:
                            JSON.stringify(intakeold) !== JSON.stringify(intake)
                              ? 1
                              : 0.4,
                        }}
                        onClick={() => {
                          updateIntake(
                            token,
                            user_data.id,
                            // customerid,
                            intakeold,
                            () => {
                              window.location.reload();
                            },
                            () => {}
                          );
                          setintake_edit(false);
                        }}
                      >
                        Save
                      </div>
                    </>
                  ) : (
                    <div className="btn " onClick={() => setintake_edit(true)}>
                      Edit
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              <IntakeView
                type={intake_edit ? "edit" : "view"}
                to_update={intakeold ? { ...intakeold } : {}}
                setto_update={setintakeold}
                data={
                  intake
                    ? {
                        ...intake,
                      }
                    : {}
                }
              />
            </div>
          )}
          {nav.toLowerCase() == "fc1" && currnetReport.is_published && (
            <div className="result_main">
              <div className="result_body">
                <div
                  style={{
                    width: "50%",
                    border: "1px solid var(--Gray-G-200, #e5e5e5)",
                    padding: 20,
                    height: "fit-content",
                    borderRadius: 20,
                  }}
                >
                  {/* {JSON.stringify(
                    getCalendlyUrl(
                      results_obj?.userData?.address?.state,
                      results_obj?.current_order?.product_id
                    )
                  )} */}
                  <AppointmentDropdown
                    appointments={[
                      {
                        ...(currnetReport?.fc1
                          ? { calendlyData: { ...currnetReport.fc1 } }
                          : {}),
                        isEnabled:
                          orderData[0]?.status !== "Order Completed"
                            ? true
                            : false,
                        _id: testid,
                      },
                    ]}
                    size="1"
                    no_follow={true}
                    link={
                      // getCalendlyUrl(
                      //   results_obj?.userData?.address?.state,
                      //   results_obj?.current_order?.product_id
                      // ).link
                      "https://calendly.com/d/ckfy-5hm-xy4/fertility-counsellor"
                    }
                    order_id={orderData[0].id}
                    addAppoiuntment={(id, evenetData) =>
                      addfc1(
                        token,
                        id,
                        evenetData,
                        (success) => {
                          window.location.reload();
                        },
                        (err) => {}
                      )
                    }
                    cancelAppoiuntment={(id) =>
                      addfc1(
                        token,
                        id,
                        {},
                        (success) => {
                          window.location.reload();
                        },
                        (err) => {}
                      )
                    }
                    createAppoiuntment={""}
                    completeAppoiuntment={(id) =>
                      completefc1(
                        token,
                        id,
                        (success) => {
                          window.location.reload();
                        },
                        (err) => {}
                      )
                    }
                    hide_divider={true}
                  />
                  {orderData[0]?.status != "Order Completed" ? (
                    <div
                      className="btn dark_btn"
                      style={{ width: "fit-content", margin: "0 0 0 auto" }}
                      onClick={() => {
                        // return;
                        updateAssignedTo(
                          token,
                          "System",
                          "/test/" + testid,
                          (success) => {
                            if (success?.success) {
                              navigate("../../../");
                              setTimeout(() => {
                                window.location.reload();
                              }, 10);
                            }
                          },
                          () => {}
                        );
                      }}
                    >
                      Mark as Complete
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div style={{ width: "50%" }}>
                  <ActivityTracker {...activity_props} filter={["Notes"]} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Test;
