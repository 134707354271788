import React, { useEffect, useState } from "react";
import {
  calculateAge,
  dateFormatter,
  formatPhone,
  intake_options,
} from "../../../utils/utils";
import CustInputMuiClone from "../../CustInput/CustInputMuiClone";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DifferentSelector } from "../../CustInput/DifferentSelector";
import CustDropdown from "../../CustInput/CustDropdown";
import { CustDropDownIntake } from "../../CustInput/CustDropDownIntake";

const IntakeView = ({ type, data, to_update, setto_update = "" }) => {
  const [intake_data, setintake_data] = useState({
    dob: "",
    personalize: {
      height_feet: 0,
      height_inch: 0,
      weight: 0,
      waist_inch: 0,
    },
    menstrual_health: {
      period_frequency: "",
      cycle_length_avg: 0,
      period_description: "",
      experience_symptoms: [""],
      symptoms: [""],
    },
    reproductive_health: {
      previous_diagnosis: "",
      goal: "",
      ultrasound_diagnosis: "",
      birth_control: "",
      medical_condition: "",
      current_medication: "",

      partner: "",
      partner_details: "",
    },
    family_history: {
      mothers_age_during_your_birth: 0,
      pregnancy_difficulty_level: "",
      menopause_mom: 0,
    },
  });
  const [previousValues, setPreviousValues] = useState([]);
  useEffect(() => {
    if (data?.dob) {
      setintake_data(data);
    }
  }, [data]);
  return (
    <div className="intake_view">
      <div className="intake_card">
        <div className="intake_title">Personal Information {type}</div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">Birthdate</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={"cust_input"}
                    sx={{
                      width: "100%",
                      "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                        borderColor: "var(--red) !important",
                        outline: "0px !important",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "var(--brand-peach-soda) !important",
                        borderWidth: "2px !important",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "5px !important",
                      },
                      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        borderRadius: "5px !important",
                      },
                    }}
                    maxDate={dayjs(dateFormatter(new Date(), "YYYY-MM-DD"))}
                    value={dayjs(
                      dateFormatter(new Date(to_update.dob), "YYYY-MM-DD")
                    )}
                    onChange={(value) => {
                      return (
                        setto_update &&
                        setto_update((prev) => ({ ...prev, dob: value }))
                      );
                    }}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              ) : (
                dateFormatter(intake_data.dob, "DD/MM/YYYY")
              )}
            </div>
          </div>
          <div className="input_group">
            <div className="input_group_header">Age</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <CustInputMuiClone
                  {...{
                    unique_id: "age",
                    attr: {
                      readonly: true,
                      error: "",
                      error_msg: "",
                      value: calculateAge(to_update.dob),
                      inputMode: "text",
                      event: {
                        change: ({ target }) => {},
                        onkeydown: (e) => {
                          if (e.key == "Enter") {
                          }
                        },
                      },
                    },
                    data: {
                      placeholder: "",
                      suffix: "",
                    },
                  }}
                />
              ) : (
                calculateAge(intake_data.dob)
              )}
            </div>
          </div>
        </div>
        {type == "edit" ? (
          <>
            <div className="intake_flex">
              <div className="input_group">
                <div className="input_group_header">Height</div>
                <div className="input_group_value">
                  {type == "edit" ? (
                    <div className="input_flex">
                      <CustInputMuiClone
                        {...{
                          unique_id: "height_feet",
                          attr: {
                            error: "",
                            error_msg: "",
                            value: to_update.personalize?.height_feet,
                            inputMode: "text",
                            event: {
                              change: ({ target }) => {
                                return (
                                  setto_update &&
                                  setto_update((prev) => ({
                                    ...prev,
                                    personalize: {
                                      ...prev.personalize,
                                      height_feet: target.value,
                                    },
                                  }))
                                );
                              },
                              onkeydown: (e) => {
                                if (e.key == "Enter") {
                                }
                              },
                            },
                          },
                          data: {
                            placeholder: "",
                            suffix: "ft",
                          },
                        }}
                      />
                      <CustInputMuiClone
                        {...{
                          unique_id: "height_inch",
                          attr: {
                            error: "",
                            error_msg: "",
                            value: to_update.personalize?.height_inch,
                            inputMode: "text",
                            event: {
                              change: ({ target }) => {
                                return (
                                  setto_update &&
                                  setto_update((prev) => ({
                                    ...prev,
                                    personalize: {
                                      ...prev.personalize,
                                      height_inch: target.value,
                                    },
                                  }))
                                );
                              },
                              onkeydown: (e) => {
                                if (e.key == "Enter") {
                                }
                              },
                            },
                          },
                          data: {
                            placeholder: "",
                            suffix: "in",
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      {intake_data?.personalize?.height_feet || "-"} ft{" "}
                      {intake_data?.personalize?.height_inch || "-"} in
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="intake_flex">
              <div className="input_group">
                <div className="input_group_header">Weight</div>
                <div className="input_group_value">
                  {type == "edit" ? (
                    <CustInputMuiClone
                      {...{
                        unique_id: "weight",
                        attr: {
                          error: "",
                          error_msg: "",
                          value: to_update.personalize?.weight,
                          inputMode: "text",
                          event: {
                            change: ({ target }) => {
                              return (
                                setto_update &&
                                setto_update((prev) => ({
                                  ...prev,
                                  personalize: {
                                    ...prev.personalize,
                                    weight: target.value,
                                  },
                                }))
                              );
                            },
                            onkeydown: (e) => {
                              if (e.key == "Enter") {
                              }
                            },
                          },
                        },
                        data: {
                          placeholder: "",
                          suffix: "kgs",
                        },
                      }}
                    />
                  ) : (
                    <>{intake_data?.personalize?.weight || "-"} kgs</>
                  )}
                </div>
              </div>
              <div className="input_group">
                <div className="input_group_header">Waist</div>
                <div className="input_group_value">
                  {type == "edit" ? (
                    <CustInputMuiClone
                      {...{
                        unique_id: "waist_inch",
                        attr: {
                          error: "",
                          error_msg: "",
                          value: to_update.personalize?.waist_inch,
                          inputMode: "text",
                          event: {
                            change: ({ target }) => {
                              return (
                                setto_update &&
                                setto_update((prev) => ({
                                  ...prev,
                                  personalize: {
                                    ...prev.personalize,
                                    waist_inch: target.value,
                                  },
                                }))
                              );
                            },
                            onkeydown: (e) => {
                              if (e.key == "Enter") {
                              }
                            },
                          },
                        },
                        data: {
                          placeholder: "",
                          suffix: "in",
                        },
                      }}
                    />
                  ) : (
                    <>{intake_data?.personalize?.waist_inch || "-"} in</>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="intake_flex">
            <div className="input_group">
              <div className="input_group_header">Height</div>
              <div className="input_group_value">
                {intake_data?.personalize?.height_feet || "-"} ft{" "}
                {intake_data?.personalize?.height_inch || "-"} in
              </div>
            </div>
            <div className="input_group">
              <div className="input_group_header">Weight</div>
              <div className="input_group_value">
                {intake_data?.personalize?.weight || "-"} kgs
              </div>
            </div>
            <div className="input_group">
              <div className="input_group_header">Waist</div>
              <div className="input_group_value">
                {intake_data?.personalize?.waist_inch || "-"} in
              </div>
            </div>
          </div>
        )}

        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">
              HOW DID YOU HEAR ABOUT ARVA?
            </div>
            <div className="input_group_value">
              {type == "edit" ? (
                <>
                  <CustInputMuiClone
                    {...{
                      unique_id: "HOWDIDYOUHEARABOUTARVA?",
                      attr: {
                        dataset: {
                          id: "HOWDIDYOUHEARABOUTARVA",
                          list: intake_options?.know_arva || [],
                        },
                        error: "",
                        error_msg: "",
                        value:
                          to_update.personalize?.where_did_you_hear_about_us,
                        inputMode: "text",
                        event: {
                          change: ({ target }) => {
                            return (
                              setto_update &&
                              setto_update((prev) => ({
                                ...prev,
                                personalize: {
                                  ...prev.personalize,
                                  where_did_you_hear_about_us: target.value,
                                },
                              }))
                            );
                          },
                          onkeydown: (e) => {
                            if (e.key == "Enter") {
                            }
                          },
                        },
                      },
                      data: {
                        placeholder: "",
                        suffix: "",
                      },
                    }}
                  />
                </>
              ) : (
                intake_data?.personalize?.where_did_you_hear_about_us
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="intake_card">
        <div className="intake_title">MENSTRUAL HEALTH</div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">PERIOD</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <CustDropDownIntake
                  custclass=""
                  inp_obj={{
                    options: [
                      ...intake_options?.period?.filter(
                        (item) =>
                          to_update?.menstrual_health?.period_frequency != item
                      ),
                    ],
                    value: to_update?.menstrual_health?.period_frequency,
                    on_event: (data) => {
                      return (
                        setto_update &&
                        setto_update((prev) => ({
                          ...prev,
                          menstrual_health: {
                            ...prev.menstrual_health,
                            period_frequency: data,
                          },
                        }))
                      );
                    },
                  }}
                />
              ) : (
                intake_data?.menstrual_health?.period_frequency
              )}
            </div>
          </div>
        </div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">LENGTH</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <CustInputMuiClone
                  {...{
                    unique_id: "cycle_length_avg",
                    attr: {
                      error: "",
                      error_msg: "",
                      value: to_update.menstrual_health?.cycle_length_avg,
                      inputMode: "text",
                      event: {
                        change: ({ target }) => {
                          return (
                            setto_update &&
                            setto_update((prev) => ({
                              ...prev,
                              menstrual_health: {
                                ...prev.menstrual_health,
                                cycle_length_avg: target.value,
                              },
                            }))
                          );
                        },
                        onkeydown: (e) => {
                          if (e.key == "Enter") {
                          }
                        },
                      },
                    },
                    data: {
                      placeholder: "",
                      suffix: "days",
                    },
                  }}
                />
              ) : (
                intake_data?.menstrual_health?.cycle_length_avg
              )}
            </div>
          </div>
          <div className="input_group">
            <div className="input_group_header">FLOW LEVEL</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <CustDropDownIntake
                  custclass=""
                  inp_obj={{
                    options: [
                      ...intake_options?.period_flow?.filter(
                        (item) =>
                          to_update?.menstrual_health?.period_description !=
                          item
                      ),
                    ],
                    value: to_update?.menstrual_health?.period_description,
                    on_event: (data) => {
                      return (
                        setto_update &&
                        setto_update((prev) => ({
                          ...prev,
                          menstrual_health: {
                            ...prev.menstrual_health,
                            period_description: data,
                          },
                        }))
                      );
                    },
                  }}
                />
              ) : (
                intake_data?.menstrual_health?.period_description
              )}
            </div>
          </div>
        </div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">SYMPTOMS</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <DifferentSelector
                  list={intake_options?.symptoms}
                  onMultiClick={(el) =>
                    setto_update &&
                    setto_update((prev) => ({
                      ...prev,
                      menstrual_health: {
                        ...prev.menstrual_health,
                        experience_symptoms:
                          prev?.menstrual_health?.experience_symptoms.includes(
                            el
                          )
                            ? prev?.menstrual_health?.experience_symptoms.filter(
                                (item) => item !== el
                              )
                            : [
                                ...prev.menstrual_health?.experience_symptoms,
                                el,
                              ],
                      },
                    }))
                  }
                  acceptList={to_update?.menstrual_health?.experience_symptoms}
                />
              ) : (
                <div className="intake_chip_flex">
                  {intake_data?.menstrual_health?.experience_symptoms.length > 0
                    ? intake_data.menstrual_health.experience_symptoms.map(
                        (el) => <div className="intake_chip">{el}</div>
                      )
                    : "-"}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">BODY HAIR</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <DifferentSelector
                  list={intake_options?.body_hair}
                  onMultiClick={(el) =>
                    setto_update &&
                    setto_update((prev) => ({
                      ...prev,
                      menstrual_health: {
                        ...prev.menstrual_health,
                        symptoms: prev.menstrual_health.symptoms.includes(el)
                          ? prev.menstrual_health.symptoms.filter(
                              (item) => item !== el
                            )
                          : [...prev.menstrual_health?.symptoms, el],
                      },
                    }))
                  }
                  acceptList={to_update.menstrual_health?.symptoms}
                />
              ) : (
                <div className="intake_chip_flex">
                  {intake_data.menstrual_health?.symptoms.length > 0
                    ? intake_data.menstrual_health?.symptoms.map((el) => (
                        <div className="intake_chip">{el}</div>
                      ))
                    : "-"}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="intake_card">
        <div className="intake_title">REPRODUCTIVE HEALTH</div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">DIAGNOSIS</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <DifferentSelector
                  list={intake_options?.diagnosis}
                  onMultiClick={(el) =>
                    setto_update &&
                    setto_update((prev) => ({
                      ...prev,
                      reproductive_health: {
                        ...prev.reproductive_health,
                        previous_diagnosis:
                          prev.reproductive_health?.previous_diagnosis.includes(
                            el
                          )
                            ? prev.reproductive_health?.previous_diagnosis.filter(
                                (item) => item !== el
                              )
                            : [
                                ...prev.reproductive_health?.previous_diagnosis,
                                el,
                              ],
                      },
                    }))
                  }
                  acceptList={to_update.reproductive_health?.previous_diagnosis}
                />
              ) : intake_data?.reproductive_health?.previous_diagnosis
                  .length ? (
                <div className="intake_chip_flex">
                  {intake_data?.reproductive_health?.previous_diagnosis?.map(
                    (el) => (
                      <div className="intake_chip">{el}</div>
                    )
                  )}
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">FERTILITY GOAL</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <>
                  {/* <div className="intake_chip">
                    {to_update.reproductive_health.goal}
                  </div> */}
                  <CustDropDownIntake
                    custclass=""
                    inp_obj={{
                      options: [
                        ...intake_options?.goal?.filter(
                          (item) => to_update.reproductive_health?.goal != item
                        ),
                      ],
                      value: to_update.reproductive_health?.goal,
                      on_event: (data) => {
                        return (
                          setto_update &&
                          setto_update((prev) => ({
                            ...prev,
                            reproductive_health: {
                              ...prev.reproductive_health,
                              goal: data,
                            },
                          }))
                        );
                      },
                    }}
                  />
                </>
              ) : (
                intake_data?.reproductive_health?.goal || "-"
              )}
            </div>
          </div>
        </div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">ULTRASOUND</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <>
                  {/* <div className="intake_chip">
                    {to_update.reproductive_health.goal}
                  </div> */}
                  <CustDropDownIntake
                    custclass=""
                    inp_obj={{
                      options: [...intake_options?.ultrasound],
                      value:
                        to_update.reproductive_health?.ultrasound_diagnosis,
                      on_event: (data) => {
                        return (
                          setto_update &&
                          setto_update((prev) => ({
                            ...prev,
                            reproductive_health: {
                              ...prev.reproductive_health,
                              ultrasound_diagnosis: data,
                            },
                          }))
                        );
                      },
                    }}
                  />
                </>
              ) : (
                intake_data?.reproductive_health?.ultrasound_diagnosis || "-"
              )}
            </div>
          </div>
        </div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">BIRTH CONTROL</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <>
                  {/* <div className="intake_chip">
                    {to_update.reproductive_health.goal}
                  </div> */}
                  <CustDropDownIntake
                    custclass=""
                    inp_obj={{
                      options: ["Yes", "No"],
                      value: to_update.reproductive_health?.birth_control,
                      on_event: (data) => {
                        return (
                          setto_update &&
                          setto_update((prev) => ({
                            ...prev,
                            reproductive_health: {
                              ...prev.reproductive_health,
                              birth_control: data,
                            },
                          }))
                        );
                      },
                    }}
                  />
                </>
              ) : (
                intake_data?.reproductive_health?.birth_control || "-"
              )}
            </div>
          </div>
        </div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">MEDICAL CONDITIONS</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <CustInputMuiClone
                  {...{
                    unique_id: "medical_condition",
                    attr: {
                      error: "",
                      error_msg: "",
                      value: to_update.reproductive_health?.medical_condition,
                      inputMode: "text",
                      event: {
                        change: ({ target }) => {
                          return (
                            setto_update &&
                            setto_update((prev) => ({
                              ...prev,
                              reproductive_health: {
                                ...prev.reproductive_health,
                                medical_condition: target.value,
                              },
                            }))
                          );
                        },
                        onkeydown: (e) => {
                          if (e.key == "Enter") {
                          }
                        },
                      },
                    },
                    data: {
                      placeholder: "",
                      suffix: "",
                    },
                  }}
                />
              ) : (
                intake_data?.reproductive_health?.medical_condition || "-"
              )}
            </div>
          </div>
        </div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">MEDICATION</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <CustInputMuiClone
                  {...{
                    unique_id: "current_medication",
                    attr: {
                      error: "",
                      error_msg: "",
                      value: to_update.reproductive_health?.current_medication,
                      inputMode: "text",
                      event: {
                        change: ({ target }) => {
                          return (
                            setto_update &&
                            setto_update((prev) => ({
                              ...prev,
                              reproductive_health: {
                                ...prev.reproductive_health,
                                current_medication: target.value,
                              },
                            }))
                          );
                        },
                        onkeydown: (e) => {
                          if (e.key == "Enter") {
                          }
                        },
                      },
                    },
                    data: {
                      placeholder: "",
                      suffix: "",
                    },
                  }}
                />
              ) : (
                intake_data?.reproductive_health?.current_medication || "-"
              )}
            </div>
          </div>
        </div>

        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">
              DO YOU HAVE A PARTNER IN YOUR LIFE?
            </div>
            <div className="input_group_value">
              {" "}
              {type == "edit" ? (
                <DifferentSelector
                  list={intake_options?.partner}
                  onMultiClick={(el) =>
                    setto_update &&
                    setto_update((prev) => {
                      const partnerList = prev.reproductive_health?.partner;
                      let updatedPartnerList;
                      if (el === "Nope") {
                        if (partnerList.includes("Nope")) {
                          updatedPartnerList = previousValues;
                        } else {
                          setPreviousValues(partnerList);
                          updatedPartnerList = ["Nope"];
                        }
                      } else {
                        if (partnerList.includes(el)) {
                          updatedPartnerList = partnerList.filter(
                            (item) => item !== el
                          );
                        } else {
                          updatedPartnerList = [...partnerList, el].filter(
                            (item) => item !== "Nope"
                          );
                        }
                      }

                      return {
                        ...prev,
                        reproductive_health: {
                          ...prev.reproductive_health,
                          partner: updatedPartnerList,
                        },
                      };
                    })
                  }
                  acceptList={to_update.reproductive_health?.partner}
                />
              ) : (
                intake_data?.reproductive_health?.partner || "-"
              )}
            </div>
          </div>
        </div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">
              CAN YOU TELL US ABOUT YOUR PARTNER?
            </div>
            <div className="input_group_value">
              {type == "edit" ? (
                <>
                  {/* <div className="intake_chip">
                    {to_update.reproductive_health.goal}
                  </div> */}
                  <CustDropDownIntake
                    custclass=""
                    inp_obj={{
                      options: [
                        ...intake_options?.about_partner?.filter(
                          (item) =>
                            to_update.reproductive_health?.partner_details !=
                            item
                        ),
                      ],
                      value: to_update.reproductive_health?.partner_details,
                      on_event: (data) => {
                        return (
                          setto_update &&
                          setto_update((prev) => ({
                            ...prev,
                            reproductive_health: {
                              ...prev.reproductive_health,
                              partner_details: data,
                            },
                          }))
                        );
                      },
                    }}
                  />
                </>
              ) : (
                intake_data?.reproductive_health?.partner_details || "-"
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="intake_card">
        <div className="intake_title">FAMILY HISTORY</div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">MOM - AGE OF PREGNANCY</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <CustInputMuiClone
                  {...{
                    unique_id: "current_medication",
                    attr: {
                      error: "",
                      error_msg: "",
                      value:
                        to_update.family_history?.mothers_age_during_your_birth,
                      inputMode: "text",
                      event: {
                        change: ({ target }) => {
                          return (
                            setto_update &&
                            setto_update((prev) => ({
                              ...prev,
                              family_history: {
                                ...prev.family_history,
                                mothers_age_during_your_birth: target.value,
                              },
                            }))
                          );
                        },
                        onkeydown: (e) => {
                          if (e.key == "Enter") {
                          }
                        },
                      },
                    },
                    data: {
                      placeholder: "",
                      suffix: "years",
                    },
                  }}
                />
              ) : (
                intake_data?.family_history?.mothers_age_during_your_birth ||
                "-"
              )}
            </div>
          </div>
        </div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">MOM - EASE OF PREGNANCY</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <CustDropDownIntake
                  custclass=""
                  inp_obj={{
                    options: [
                      ...intake_options?.mom_ease_of_pregnancy?.filter(
                        (item) =>
                          to_update.family_history
                            ?.pregnancy_difficulty_level != item
                      ),
                    ],
                    value: to_update.family_history?.pregnancy_difficulty_level,
                    on_event: (data) => {
                      return (
                        setto_update &&
                        setto_update((prev) => ({
                          ...prev,
                          family_history: {
                            ...prev.family_history,
                            pregnancy_difficulty_level: data,
                          },
                        }))
                      );
                    },
                  }}
                />
              ) : (
                intake_data?.family_history?.pregnancy_difficulty_level || "-"
              )}
            </div>
          </div>
        </div>
        <div className="intake_flex">
          <div className="input_group">
            <div className="input_group_header">MOM - MENOPAUSE</div>
            <div className="input_group_value">
              {type == "edit" ? (
                <CustInputMuiClone
                  {...{
                    unique_id: "menopause_mom",
                    attr: {
                      error: "",
                      error_msg: "",
                      value: to_update.family_history?.menopause_mom,
                      inputMode: "text",
                      event: {
                        change: ({ target }) => {
                          return (
                            setto_update &&
                            setto_update((prev) => ({
                              ...prev,
                              family_history: {
                                ...prev.family_history,
                                menopause_mom: target.value,
                              },
                            }))
                          );
                        },
                        onkeydown: (e) => {
                          if (e.key == "Enter") {
                          }
                        },
                      },
                    },
                    data: {
                      placeholder: "",
                      suffix: "years",
                    },
                  }}
                />
              ) : (
                intake_data?.family_history?.menopause_mom || "-"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntakeView;
