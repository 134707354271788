import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OrderTypeHeader from "../OrderTypeHeader";
import { useState } from "react";
import {
  addAppoiuntment,
  cancelAppoiuntment,
  completeAppoiuntment,
  createAppoiuntment,
  getDataByID,
  getOrderItem,
  getOrderItemCoaching,
  updateAssignedTo,
} from "../../../../../services/api";
import { svg } from "../../../../../services/svg";
import ActivityTracker from "../components/ActivityTracker/ActivityTracker";
import { dateFormatter, getCalendlyUrl } from "../../../../../utils/utils";
import AppointmentDropdown from "../components/AppointmentDropdown/AppointmentDropdown";
import { useSelector } from "react-redux";
import CustDropdown from "../../../../../components/CustInput/CustDropdown";
import Results from "../../components/Results";
import IntakeView from "../../../../../components/Intake/IntakeView/IntakeView";

const Coaching = ({ is_bundle }) => {
  const {
    user: { token },

    employee = [],
  } = useSelector((state) => state.appState);

  const store = useSelector((state) => state.appState);
  const [current_open_dropdown, setcurrent_open_dropdown] = useState("");

  const dropdown_settings = {
    is_open: current_open_dropdown,
    setdropdown: setcurrent_open_dropdown,
  };
  const { orderid, bundleid, coachingid } = useParams();
  const navigate = useNavigate();
  const [nav, setnav] = useState("Overview");

  const [activity, setactivity] = useState([]);
  const [appointments, setappointments] = useState([]);
  const [orderData, setorderData] = useState([
    {
      orderId: "1006",
      order: {
        _id: "664c64770265c63ca3a00196",
        orderId: "0014",
        lab: {
          name: "Thyrocare Technologies",
          orderId: "ASDKJA9123",
          technician: {
            name: "Sarvesh",
            phone: "9823045624",
          },
          report: "/report/report_id",
          result: {},
          activity: {
            type: "status change",
            by: {
              name: "System",
            },
            dateTime: "7:30 pm",
          },
        },
      },
      customer: {
        name: "John Doe",
        phone: "1234567890",
        address: {
          pincode: "560034",
          address: "5th Block",
          apt: "55",
          landmark: "Koramangala",
          city: "Banglore",
        },
      },
      product: {
        name: "Egg Freezing Consultation",
        type: "consult",
        sku: "DC02",
        goTo: "/user/user_id",
        bundle: {
          name: "Egg Freesing Bundle",
          goTo: "/bundle/bundle_id",
        },
      },
      status: "Order Confirmed",
      assignedTo: "System",
      priority: "Low",
      dates: {
        order: "21/05/2024",
        appointment: "Saturday, Apr 14, 11:00 AM",
      },
      amount: {
        gross: 5160,
        discount: {
          details: {
            name: "WELCOME20",
          },
        },
      },
      invoice: {
        invoiceId: "AH0014",
        goTo: "/invoice/invoice_id",
      },
    },
  ]);
  const [results_obj, setresults_obj] = useState({
    results: [],
    current_order: {},
    userData: {},
    testid: "",
  });
  const [intakeold, setintakeold] = useState({});
  const [intake, setintake] = useState({});

  const [reports_obj, setreports_obj] = useState({});

  useEffect(() => {
    getDataByID(
      token,
      coachingid,
      "coaching",
      (orderItem) => {
        if (!orderData) {
          return;
        }
        //
        if (orderItem) {
          setorderData(
            [orderItem.item].map((el) => {
              setappointments(el.appointment);
              const {
                order,
                product_status,
                product_priority,
                dates,
                assigned_to,
                product_price,
                product_name,
                product_type,
                product_id,
                bundle_name,
                user,
                _id,
                discount_code,
              } = el;

              return {
                orderId: order?.orderId || "",
                order: {
                  _id: order?._id || "",
                  orderId: order?.orderId || "",
                  lab: {
                    name: "",
                    orderId: "",
                    technician: {
                      name: "",
                      phone: "",
                    },
                    report: "/report/report_id",
                    result: {},
                    activity: {
                      type: "status change",
                      by: {
                        name: "System",
                      },
                      dateTime: "7:30 pm",
                    },
                  },
                },
                customer: {
                  name:
                    [
                      user?.user?.first_name || "",
                      user?.user?.last_name || "",
                    ].join(" ") || "",
                  phone: user.user.phone,
                  email: user.user.email,
                  goTo: "dashboard/customers/" + user?.user_id + "/overview",
                  address: {
                    ...user.address,
                  },
                },
                product: {
                  name: product_name,
                  type: product_type,
                  sku: product_id,
                  goTo:
                    product_type == "guide"
                      ? ""
                      : "/" + product_type + "/" + _id,
                  bundle: {
                    name: orderItem?.bundle?.name || "",
                    goTo: "/bundle/" + orderItem?.bundle?.bundleId || "",
                  },
                },
                status: product_status,
                assignedTo: assigned_to,
                priority: product_priority,
                dates: {
                  ...dates,
                },
                amount: {
                  gross: product_price,
                  discount: {
                    details: {
                      name: discount_code,
                    },
                  },
                },
                invoice: {
                  invoiceId: order?.orderId ? "AH" + order.orderId : "",
                  goTo:
                    "/invoice/" + order?.orderId ? "AH" + order.orderId : "",
                },
                id: el._id,
              };
            })
          );
          // setresults_obj({
          //   results: orderItem.reports,
          //   current_order: orderItem.item,
          //   userData: orderItem.userData,
          //   testid: "",
          // });
          setreports_obj({
            reports: [...(orderItem?.reports || [])],
            activity: {
              show: false,
              data: [],
            },
            intake: {
              ...(orderItem?.userData?.intake || {}),
            },
          });
          setactivity(orderItem?.activity || []);
          setintake({ ...(orderItem?.userData?.intake || {}) });
          setintakeold({ ...(orderItem?.userData?.intake || {}) });
        }
      },
      () => {}
    );
  }, []);
  return (
    <div className=" dashboard_page">
      <OrderTypeHeader
        {...{
          breadCrumbs: [
            {
              name: orderData[0]?.order?.orderId
                ? "#" + orderData[0]?.order?.orderId
                : "NA",
            },
            is_bundle
              ? {
                  name: orderData[0]?.product?.bundle?.name || "NA",
                  goto: orderData[0]?.product?.bundle?.goTo
                    ? "../../../orders/" +
                      orderData[0]?.order?.orderId +
                      orderData[0]?.product?.bundle?.goTo
                    : "",
                }
              : null,
            {
              name: orderData[0]?.product?.name || "NA",
              // goto: orderData[0]?.product?.goTo || "",
            },
          ].filter((el) => el),
          orderDate: orderData[0]?.dates?.order || "",
          customer: {
            name: orderData[0]?.customer?.name || "",
            goTo: orderData[0]?.customer?.goTo || "",
          },
          phone: orderData[0]?.customer?.phone || "",
          email: orderData[0]?.customer?.email || "",
          nav_list: ["Overview", "Results"],
          nav,
          setnav,
        }}
      />
      <div className="appointment_body dashboard_body">
        {nav == "Overview" && (
          <>
            <div className="body_left">
              {!["counselor", "doctor"].includes(
                store?.user?.type?.toLowerCase()
              ) ? (
                <div className="summary">
                  <div className="amount_main_group">
                    <div className="status_group">
                      <div className="title">Status</div>
                      <div
                        className="order_status chips"
                        {...{
                          color: {
                            High: 2,
                            Medium: 4,
                            Low: "",
                          }[orderData[0]?.priority || ""],
                        }}
                        s
                      >
                        {orderData[0]?.status || (
                          <span className="na">N/A</span>
                        )}
                      </div>
                    </div>
                    {!["counselor", "doctor"].includes(
                      store?.user?.type?.toLowerCase()
                    ) ? (
                      <div className="assignedto_group">
                        <div className="title">Assigned to</div>
                        <div className="body">
                          {orderData[0]?.assignedTo ? (
                            <CustDropdown
                              {...{
                                ...dropdown_settings,
                                dropdown_align: "left_align",
                                multiselect: false,
                                title: orderData[0]?.assignedTo,
                                value: orderData[0]?.assignedTo,
                                setvalue: (value) => {
                                  if (value[0]) {
                                    // navigate(value[0]);
                                    updateAssignedTo(
                                      token,
                                      value[0],
                                      "/test/" + coachingid,
                                      (success) => {
                                        if (success?.success) {
                                          window.location.reload();
                                        }
                                      },
                                      () => {}
                                    );
                                  }
                                },
                                list: [
                                  ...employee?.map((el) => ({
                                    key: el,
                                    value: el,
                                  })),
                                ],
                                list_show: (data) => data.value,
                              }}
                            />
                          ) : (
                            <span className="na">N/A</span>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="amount_main_group">
                    <div className="amount_group">
                      <div className="title">Amount</div>
                      <div className="body">
                        {orderData[0]?.amount?.gross || (
                          <span className="na">N/A</span>
                        )}
                      </div>
                    </div>
                    <div className="discount_group">
                      <div className="title">Discount</div>
                      <div className="body">
                        {orderData[0]?.amount?.discount?.details?.name || (
                          <span className="na">N/A</span>
                        )}
                      </div>
                    </div>
                    {/* <div
                    className="invoice_group"
                    onClick={() => window.open(orderData[0]?.invoice?.goTo)}
                  >
                    <div className="title">Invoice</div>
                    <div className="body">
                      {orderData[0]?.invoice?.invoiceId
                        ? orderData[0]?.invoice?.invoiceId
                        : "-"}
                      {svg.chain}
                    </div>
                  </div> */}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="lab_summary">
                <div className="title">
                  Appointment
                  <div className="goto">
                    Go to booking page {svg.chevron.right}
                  </div>
                </div>
                <AppointmentDropdown
                  appointments={[
                    ...new Array(
                      Math.max(
                        orderData[0]?.product?.name.match(/\d+/)
                          ? parseInt(
                              orderData[0]?.product?.name.match(/\d+/)[0]
                            )
                          : 1,
                        appointments.length
                      )
                    ),
                  ].map((el, i) => {
                    return appointments[i] || {};
                  })}
                  no_follow={true}
                  size={Math.max(
                    orderData[0]?.product?.name.match(/\d+/)
                      ? parseInt(orderData[0]?.product?.name.match(/\d+/)[0])
                      : 1,
                    appointments.length
                  )}
                  // size={4}
                  link={
                    getCalendlyUrl(
                      results_obj?.userData?.address?.state,
                      results_obj?.current_order?.product_id
                    ).link || ""
                  }
                  schedule={(eventData) => {}}
                  cancel={(eventData) => {}}
                  complete={(eventData) => {}}
                  order_id={orderData[0].id}
                  addAppoiuntment={(id, evenetData) =>
                    addAppoiuntment(
                      token,
                      id,
                      evenetData,
                      (success) => {
                        window.location.reload();
                      },
                      (err) => {}
                    )
                  }
                  cancelAppoiuntment={(id) =>
                    cancelAppoiuntment(
                      token,
                      id,
                      (success) => {
                        window.location.reload();
                      },
                      (err) => {}
                    )
                  }
                  createAppoiuntment={(order_id) =>
                    createAppoiuntment(
                      token,
                      order_id,
                      (success) => {
                        window.location.reload();
                      },
                      (err) => {}
                    )
                  }
                  completeAppoiuntment={(id) =>
                    completeAppoiuntment(
                      token,
                      id,
                      (success) => {
                        window.location.reload();
                      },
                      (err) => {}
                    )
                  }
                />
              </div>
            </div>
            <div className="body_right">
              <ActivityTracker data={[...activity]} />
            </div>
          </>
        )}
        {nav == "Results" && <Results {...{ ...reports_obj }} />}
        {nav == "Intake" && (
          <div className="invoice_main">
            <IntakeView
              type={"view"}
              to_update={intakeold ? { ...intakeold } : {}}
              data={
                intake
                  ? {
                      ...intake,
                    }
                  : {}
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Coaching;
