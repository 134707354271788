import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CustDropdown from "../../../../components/CustInput/CustDropdown";
import CustSearch from "../../../../components/CustInput/CustSearch";
import CustTable from "../../../../components/Table/CustTable";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../../../services/api";
import { formatPhone } from "../../../../utils/utils";

const Customers = () => {
  const {
    user: { token },
    products,
    allcustomers,
  } = useSelector((state) => state.appState);

  const [order_search, setorder_search] = useState("");
  const [users, setusers] = useState([
    // {
    //   customerName: "Alice",
    //   phone: "1234567890",
    //   email: "alice@example.com",
    //   date: "05/30/2024",
    //   goal: "Learn JavaScript",
    // },
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (allcustomers) {
      setusers(allcustomers);
    }
    getCustomers(
      token,
      (success) => {
        if (success) {
          //
          let users = success.map(
            ({
              _id,
              first_name,
              last_name,
              prefix,
              phone,
              email,
              goal,
              lastOrderDate,
            }) => ({
              customerName: `${first_name} ${last_name}`,
              phone: formatPhone(phone, prefix),
              email,
              date: lastOrderDate,
              goal,
              id: _id,
            })
          );
          setusers(users);
          dispatch({
            type: "setAppState",
            subType: "allcustomers",
            payload: [...users],
          });
        }
      },
      (err) => {
        //
      }
    );

    return () => {};
  }, []);

  return (
    <div className="dashboard_body customer_page orders_page">
      <div className="dashboard_subheader">
        <div className="title">Customers</div>

        <div className="search_and_filter">
          <CustSearch
            {...{
              value: order_search,
              setvalue: setorder_search,
            }}
          />
        </div>
      </div>
      <div className="dashboard_subbody">
        <div className="customer_table">
          <CustTable
            {...{
              data: users
                ?.filter((el) =>
                  el.customerName
                    .toLowerCase()
                    .includes(order_search.toLowerCase())
                )
                .map((el) => ({
                  ...el,
                  rowClick: () => navigate(`../${el.id}/overview`),
                })),
              nosrnogap: true,
              header: [
                {
                  id: "customerName",
                  title: "Customer",
                },
                {
                  id: "phone",
                  title: "Phone Number",
                },
                {
                  id: "email",
                  title: "Email",
                },
                {
                  id: "date",
                  title: "Last Order Date",
                },
                {
                  id: "goal",
                  title: "Goal",
                },
              ],
              onrowclick: (row_data) => {},
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Customers;
